import jsonexport from "jsonexport"
import moment from "moment-timezone"
import * as XLSX from "xlsx"
import { DefaultRoute } from "../router/routes"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) =>
  (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
export const getUserData = () => JSON.parse(localStorage.getItem("userData"))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return DefaultRoute
  if (userRole === "client") return "/access-control"
  return "/login"
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "rgba(105, 172, 223, 1)", // for selected option bg-color
    neutral10: "rgba(105, 172, 223, 1)", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})

function convertArrayOfObjectsToCSVCustomers(array) {
  let result

  const columnDelimiter = ","
  const lineDelimiter = "\n"
  const keys = Object.keys(array[0])

  result = ""
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  array.forEach((item) => {
    let ctr = 0
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter
      if (
        key === "id" ||
        key === "firstName" ||
        key === "lastName" ||
        key === "email" ||
        key === "phoneNumber" ||
        key === "comment"
      ) {
        result += item[key]
      }

      if (key === "assignedUsers" && item[key].length > 0) {
        result += `${item[key][0].lastName} ${item[key][0].firstName}`
      }
      ctr++
    })
    result += lineDelimiter
  })

  return result
}

export async function downloadExcel(array, name, type) {
  // Convert the array to a workbook
  const worksheet = XLSX.utils.json_to_sheet(array)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

  // Generate buffer
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" })

  // Create a Blob
  const blob = new Blob([wbout], { type: "application/octet-stream" })

  // Create an anchor element and download the file
  const link = document.createElement("a")
  const url = URL.createObjectURL(blob)
  link.href = url
  link.download = `${name || "export"}-${moment().format(
    "YYYY-MM-DD HH:mm"
  )}.xlsx`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

export async function downloadCSV(array, type) {
  const link = document.createElement("a")
  let csv = await jsonexport(array)

  const filename = "export.csv"

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`
  }

  link.setAttribute("href", encodeURI(csv))
  link.setAttribute("download", filename)
  link.click()
}

export function downloadCSVCustomer(array, type) {
  const link = document.createElement("a")
  let csv = convertArrayOfObjectsToCSVCustomers(array)
  if (csv === null) return

  const filename = "export.csv"

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`
  }

  link.setAttribute("href", encodeURI(csv))
  link.setAttribute("download", filename)
  link.click()
}

export const paymentTypeDict = {
  ONLINE: "Online",
  OFFLINE: "Stacjonarnie",
  BANK_TRANSFER: "Przelew bankowy",
  CREDIT_CARD: "Karta kredytowa",
  CASH: "Gotówka",
  "N/A": "N/A"
}

export const paymentStatusDict = {
  COMPLETED_AUTOMATICALLY: "Opłacono",
  COMPLETED_MANUALLY: "Opłacono",
  PENDING: "Oczekuje"
}

export const appointmentStatusDict = {
  SCHEDULED: "Zaplanowana",
  COMPLETED: "Zrealizowana",
  CANCELED: "Odwołana w terminie",
  "CANCELED-PAID": "Odwołana poza terminem",
  DELETED: "Skasowana"
}

export const appointementStatusColor = {
  COMPLETED: "light-success",
  SCHEDULED: "light-primary",
  CANCELED: "light-warning",
  "CANCELED-PAID": "light-danger",
  DELETED: "light-danger"
}

export const clearDataFromMeta = (
  obj,
  keys = [
    "createdBy",
    "updatedBy",
    "createdAt",
    "updatedAt",
    "deletedAt",
    "deletedBy"
  ]
) => {
  if (Array.isArray(obj)) {
    obj.forEach(function (item) {
      clearDataFromMeta(item, keys)
    })
  } else if (typeof obj === "object" && obj !== null) {
    Object.getOwnPropertyNames(obj).forEach(function (key) {
      if (keys.indexOf(key) !== -1) delete obj[key]
      else clearDataFromMeta(obj[key], keys)
    })
  }
}

function formatDateMmomentConversion(date) {
    // Ensure the input is a Date object
    if (!(date instanceof Date) || isNaN(date.getTime())) {
        throw new Error('Invalid date provided')
    }

    // Get year, month, and day
    const year = date.getFullYear()
    const month = date.getMonth() + 1 // Month is 0-indexed
    const day = date.getDate()

    // Get hours and minutes
    const hours = date.getHours()
    const minutes = date.getMinutes()

    // Pad the month, day, hours, and minutes with leading zeros if necessary
    const paddedMonth = month < 10 ? `0${month}` : month
    const paddedDay = day < 10 ? `0${day}` : day
    const paddedHours = hours < 10 ? `0${hours}` : hours
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes

    // Format the date string
    return `${year}-${paddedMonth}-${paddedDay} ${paddedHours}:${paddedMinutes}`
}


export function convertTimeToDifferentTimezone(originalDate) {
  return originalDate
  // if (!originalDate) {
  //   return originalDate
  // }
  // console.log(originalDate, formatDateMmomentConversion(originalDate))
  // console.log(moment(formatDateMmomentConversion(originalDate)).toDate())
  //   // Create a moment object from the original date
  //   return moment(formatDateMmomentConversion(originalDate)).toDate()
}


function parseDateString(dateString) {
    // Check if the dateString is correctly formatted
    if (!/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(dateString)) {
        throw new Error('Invalid date format. Please use "YYYY-MM-DD HH:mm".')
    }

    // Extract parts of the date from the string
    const parts = dateString.split(' ')
    const dateParts = parts[0].split('-')
    const timeParts = parts[1].split(':')

    // Extract year, month, day, hour, and minute from the parts
    const year = parseInt(dateParts[0], 10)
    const month = parseInt(dateParts[1], 10) - 1 // Month is 0-indexed in JavaScript
    const day = parseInt(dateParts[2], 10)
    const hour = parseInt(timeParts[0], 10)
    const minute = parseInt(timeParts[1], 10)

    // Create and return new Date object
    return new Date(year, month, day, hour, minute)
}


export function convertServerToLocale(originalDate) {
  // if (!originalDate) {
    return originalDate
  // }
    // Create a moment object from the original date
    // return parseDateString(moment(originalDate).format('YYYY-MM-DD HH:mm'))
}

