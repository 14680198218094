// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where
} from "firebase/firestore"
import { orderBy } from "lodash"
import { addDocWithUser, db, updateDocWithUser } from "../configs/firebase"
import { store } from "./store"

let servicesSubscription = null
let serviceVariantForUserSubscription = null

export const addService = createAsyncThunk(
  "services/addService",
  async ({ service, currentUser }, { dispatch, getState }) => {
    await addDocWithUser(
      collection(db, "services"),
      {
        ...service
      },
      currentUser
    )
  }
)

export const updateService = createAsyncThunk(
  "services/updateService",
  async ({ service, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "services", service.id),
        {
          ...service
        },
        currentUser
      )
    } catch (e) {
      console.error(e)
    }
  }
)

export const deleteService = createAsyncThunk(
  "services/deleteService",
  async (id, { dispatch, getState }) => {
    //   await axios.delete('/apps/services/delete', { id })
    return id
  }
)

export const getServiceVariants = createAsyncThunk(
  "services/getServiceVariants",
  async (id) => {
    try {
      const q = query(
        collection(db, "servicesVariants"),
        where("serviceId", "==", id)
      )
      const querySnapshot = await getDocs(q)
      const result = []
      querySnapshot.forEach((doc) => {
        result.push({ id: doc.id, ...doc.data() })
      })
      return result
    } catch (e) {
      console.error(e)
      return []
    }
  }
)

export const addServiceVariant = createAsyncThunk(
  "services/addServiceVariant",
  async ({ serviceVariantData, currentUser }, { dispatch, getState }) => {
    try {
      await addDocWithUser(
        collection(db, "servicesVariants"),
        {
          ...serviceVariantData
        },
        currentUser
      )
    } catch (e) {
      console.error(e)
    }
  }
)

export const addServiceVariantForUser = createAsyncThunk(
  "services/addServiceVariantForUser",
  async ({ data, currentUser }, { dispatch, getState }) => {
    await addDocWithUser(
      collection(db, "userServicesVariants"),
      {
        ...data
      },
      currentUser
    )
  }
)

export const updateServiceVariant = createAsyncThunk(
  "services/updateService",
  async ({ serviceVariantData, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "servicesVariants", serviceVariantData.id),
        {
          ...serviceVariantData
        },
        currentUser
      )
    } catch (e) {
      console.error(e)
    }
  }
)

export const updateServiceVariantForUser = createAsyncThunk(
  "services/updateServiceVariantForUser",
  async ({ serviceVariantData, currentUser }, { dispatch, getState }) => {
    await updateDocWithUser(
      doc(db, "userServicesVariants", serviceVariantData.id),
      {
        ...serviceVariantData
      },
      currentUser
    )
  }
)

export const deleteServiceVariant = createAsyncThunk(
  "services/deleteServiceVariant",
  async (variant, { dispatch, getState }) => {
    await deleteDoc(doc(db, "servicesVariants", variant.id))
    await dispatch(getServiceVariants(variant.serviceId))
  }
)

export const servicesSlice = createSlice({
  name: "services",
  initialState: {
    data: [],
    serviceVariants: [],
    serviceVariantForUser: [],
    serviceVariantForUserUpdatedAt: Date.now().toString()
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setServicVariantForUserData(state, action) {
      state.serviceVariantForUser = action.payload
      state.serviceVariantForUserUpdatedAt = Date.now().toString()
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceVariants.fulfilled, (state, action) => {
      state.serviceVariants = action.payload
    })
  }
})

export const subscribeToServices = () => {
  if (servicesSubscription) {
    servicesSubscription()
  }
  try {
    servicesSubscription = onSnapshot(
      query(collection(db, "services"), where("isDeleted", "!=", true)),
      (data) => {
        const services = []
        data.forEach((doc) => {
          services.push({
            id: doc.id,
            ...doc.data()
          })
        })
        store.dispatch(servicesSlice.actions.setData(services))
        store.dispatch(
          servicesSlice.actions.setData(orderBy(services, ["name"], ["asc"]))
        )
        return services
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export const subscribeToServiceVariantForUser = (userId) => {
  if (serviceVariantForUserSubscription) {
    serviceVariantForUserSubscription()
  }
  try {
    serviceVariantForUserSubscription = onSnapshot(
      query(
        collection(db, "userServicesVariants"),
        where("userId", "==", userId),
        where("isDeleted", "!=", true)
      ),
      (data) => {
        const services = []
        data.forEach((doc) => {
          services.push({
            id: doc.id,
            ...doc.data(),
            startDate: doc.data().startDate ? doc.data().startDate.toDate() : false
          })
        })
        

       services.sort((a, b) => {
    // Access nested properties safely
    const getNestedProperty = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj)

    // Compare 'variant.isOnline', true first (you can invert the order if false should come first)
    if (getNestedProperty(a, 'variant.isOnline') === true && getNestedProperty(b, 'variant.isOnline') === false) {
        return 1
    }
    if (getNestedProperty(a, 'variant.isOnline') === false && getNestedProperty(b, 'variant.isOnline') === true) {
        return -1
    }

        if (window.location.href.indexOf('osrodek') > -1) {
    // Compare 'price' in ascending order
    if (a.price < b.price) {
        return 1
    }
    if (a.price > b.price) {
        return 1
    }          
        }

    // // Compare 'service.name' in descending order
    if (getNestedProperty(a, 'service.name') < getNestedProperty(b, 'service.name')) {
        return -1
    }
    if (getNestedProperty(a, 'service.name') > getNestedProperty(b, 'service.name')) {
        return 1
    }
        
            if (getNestedProperty(a, 'variant.name') < getNestedProperty(b, 'variant.name')) {
        return -1
    }
    if (getNestedProperty(a, 'variant.name') > getNestedProperty(b, 'variant.name')) {
        return 1
    }

    return 0 // if all comparisons are equal
      })
        

        store.dispatch(
          servicesSlice.actions.setServicVariantForUserData(
            services
          )
        )
        return services
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export default servicesSlice.reducer
