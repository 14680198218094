// ** React Imports
import { Fragment, lazy } from "react"
import { Navigate } from "react-router-dom"
// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"
import VerticalLayout from "@src/layouts/VerticalLayout"

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute"

// ** Utils
import { isObjEmpty } from "@utils"

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />
}

// ** Default Route
const DefaultRoute = "/login"

const Reports = lazy(() => import("../../views/reports/index"))
const Users = lazy(() => import("../../views/users/index"))
const InvoicingTable = lazy(() =>
  import("../../views/invoicing/invoicingTable")
)
const UserDetails = lazy(() => import("../../views/users/details/index"))
const AppointmentDetails = lazy(() =>
  import("../../views/appointmentDetails/index")
)
const Login = lazy(() => import("../../views/Login"))
const Locations = lazy(() => import("../../views/locations/index"))
const CustomerSources = lazy(() => import("../../views/customerSources/index"))
const Rooms = lazy(() => import("../../views/rooms/index"))
const Calendar = lazy(() => import("../../views/calendar/index"))
const CalendarTheraphist = lazy(() =>
  import("../../views/calendarTheraphist/index")
)
const Availability = lazy(() => import("../../views/availability/index"))
const TimeOff = lazy(() => import("../../views/timeOff/timeOffTable"))
const Customers = lazy(() => import("../../views/customers/index"))
const CustomerDetails = lazy(() =>
  import("../../views/customers/details/customerDetails")
)
const Appointements = lazy(() => import("../../views/appointments/index"))
const Payments = lazy(() => import("../../views/payments/paymentsTable"))
const Services = lazy(() => import("../../views/services/index"))
const Roles = lazy(() => import("../../views/roles/rolesTable"))
const ServicesVariantsTable = lazy(() =>
  import("../../views/services/details/servicesVariantsTable")
)
const Register = lazy(() => import("../../views/Register"))
const ForgotPassword = lazy(() => import("../../views/ForgotPassword"))
const Error = lazy(() => import("../../views/Error"))
const TheraphyTypes = lazy(() => import("../../views/theraphyTypes/index"))
const CustomerProblems = lazy(() =>
  import("../../views/customerProblems/index")
)
const Settings = lazy(() => import("../../views/settings/index"))
const GeneratedInvoices = lazy(() =>
  import("../../views/generatedInvoices/index")
)
const ResetPassword = lazy(() => import("../../views/resetPassword"))
const ChangePaymentType = lazy(() => import("../../views/paymentType"))
const FAQ = lazy(() => import("../../views/faq/index"))
const SlotFinder = lazy(() => import("../../views/slotFinder/index"))
const Trainings = lazy(() => import("../../views/trainings/index"))
const TrainingDetails = lazy(() =>
  import("../../views/trainings/details/trainingDetails")
)

const PaymentConfirmation = lazy(() =>
  import("../../views/paymentConfirmation")
)
const Gdpr = lazy(() =>
  import("../../views/gdpr")
)
const Payment = lazy(() => import("../../views/payment"))
const TrainingSignUp = lazy(() => import("../../views/trainingSignUp"))
const CalendarForTheraphist = lazy(() =>
  import("../../views/calendarForTheraphist")
)

// ** Merge Routes
const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={DefaultRoute} />
  },
  {
    path: "/reports",
    element: <Reports />
  },
  {
    path: "/calendar",
    element: <Calendar />
  },
  {
    path: "/slotFinder",
    element: <SlotFinder />
  },
  {
    path: "/myCalendar",
    element: <CalendarTheraphist />
  },
  {
    path: "/roles",
    element: <Roles />
  },
  {
    path: "/customers",
    element: <Customers />
  },
  {
    path: "/trainings",
    element: <Trainings />
  },
  {
    path: "/trainings:id",
    element: <TrainingDetails />
  },
  {
    path: "/customers/:id",
    element: <CustomerDetails />
  },
  {
    path: "/appointments",
    element: <Appointements />
  },
  {
    path: "/availability",
    element: <Availability />
  },
  {
    path: "/timeOff",
    element: <TimeOff />
  },

  {
    path: "/theraphyTypes",
    element: <TheraphyTypes />
  },
  {
    path: "/customerProblems",
    element: <CustomerProblems />
  },
  {
    path: "/payments",
    element: <Payments />
  },
  {
    path: "/users",
    element: <Users />
  },
  {
    path: "/users/:id",
    element: <UserDetails />
  },
  {
    path: "/me",
    element: <UserDetails />
  },
  {
    path: "/appointments/:id",
    element: <AppointmentDetails />
  },
  {
    path: "/locations",
    element: <Locations />
  },
  {
    path: "/customerSources",
    element: <CustomerSources />
  },
  {
    path: "/resetPassword",
    element: <ResetPassword />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/rooms",
    element: <Rooms />
  },
  {
    path: "/services",
    element: <Services />
  },
  {
    path: "/invoicing",
    element: <InvoicingTable />
  },
  {
    path: "/settings",
    element: <Settings />
  },
  {
    path: "/generatedInvoices",
    element: <GeneratedInvoices />
  },
  {
    path: "/faq",
    element: <FAQ />
  },
  {
    path: "/calendarForTheraphist",
    element: <CalendarForTheraphist />
  },
  {
    path: "/course",
    element: <TrainingSignUp />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/services/:id",
    element: <ServicesVariantsTable />
  },
  {
    path: "/login",
    element: <Login />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/start",
    element: <FAQ />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/register",
    element: <Register />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/paymentType",
    element: <ChangePaymentType />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/paymentConfirmation",
    element: <PaymentConfirmation />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/gdpr",
    element: <Gdpr />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/payment",
    element: <Payment />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/error",
    element: <Error />,
    meta: {
      layout: "blank"
    }
  }
]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        const RouteTag = PublicRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false)
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical"
  const layouts = ["vertical", "blank"]

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, Routes, getRoutes }
