// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where
} from "firebase/firestore"
import { orderBy } from "lodash"
import moment from "moment-timezone"
import { addDocWithUser, db, updateDocWithUser } from "../configs/firebase"
import { store } from "./store"

// ** Axios Imports

export const paymentsSubscription = {
  sub: null,
  start: null,
  end: null,
  userId: null,
  loading: true
}

const translate = (type, v) => {
  const dictionary = {
    "Rodzaj płaności": {
      ONLINE: "Online",
      OFFLINE: "Stacjonarnie",
      BANK_TRANSFER: "Przelew bankowy",
      CREDIT_CARD: "Karta kredytowa",
      CARD: "Karta kredytowa",
      CASH: "Gotówka",
      Przelewy24: "Przelewy24",
      comment: "Komentarz terapeuty",
      "N/A": "N/A"
    },

    Status: {
      SCHEDULED: "Zaplanowana",
      COMPLETED: "Zrealizowana",
      CANCELED: "Odwołana",
      "CANCELED-PAID": "Odwołana poza terminem"
    },
    "Powód odwołania": {
      THERAPHIST: "Terapeuta odwołał",
      CUSTOMER: "Klient odwołał",
      HOLIDAYS: "Urlop terapeuty"
    },
    "Powód zmiany terminu": {
      CUSTOMER: "Odwołanie klienta",
      THERAPIST_ILLNESS: "Choroba terapeuty",
      THERAPIST_VACATION: "Urlop terapeuty",
      THERAPIST_CHANGE: "Przelozona przez terapeutę",
      THERAPY_RESIGNATION: "Rezygnacja z terapii",
      PROCESS_TERMINATION: "Zakończenie procesu",
      CUSTOMER_ABSENT: "Klient nie pojawił się na wizycie"
    }
  }

  return dictionary[type] ? dictionary[type][v] : v
}

export const mapPaymentsData = (data) => {
  const payments = data.map((doc) => {
    return {
      id: doc.id,
      "Data i godzina": moment(doc.dateTime).format("YYYY-MM-DD HH:mm"),
      "Rodzaj płatności": translate("Rodzaj płaności", doc.type),
      Cena: doc.price,
      "Terapeuta - Imię": doc?.theraphist?.firstName,
      "Terapeuta - Nazwisko": doc?.theraphist?.lastName,
      "Terapeuta - ID": doc?.theraphist?.id,
      "Pacjent - ID": doc?.customer?.id,
      "Pacjent - Imię": doc?.customer?.firstName,
      "Pacjent - Nazwisko": doc?.customer?.lastName,
      "Wizyta - ID": doc?.appointment?.id,
      "Wizyta - Data i godzina": moment(doc?.appointment?.dateTime).format(
        "YYYY-MM-DD HH:mm"
      ),
      "Usługa - ID": doc?.appointment?.variant?.id,
      "Usługa - Nazwa": doc?.appointment?.variant?.name,
      "Usługa - Cena": doc?.appointment?.variant?.price,
      "Płatność dodana manualnie": doc?.isAddedManually ? "Tak" : "Nie",
      "Stworzone przez": doc?.isAddedManually ? `${doc?.createdBy?.lastName} ${doc?.createdBy?.firstName}` : "Automatycznie"
    }
  })
  return payments
}

export const mapInvoiceData = (data) => {
  const payments = data.map((doc) => {
    return {
      id: doc.id,
      "Data i godzina": moment(doc.dateTime).format("YYYY-MM-DD HH:mm"),
      "Rodzaj płatności": translate("Rodzaj płaności", doc.type),
      Cena: doc.price,
      "Terapeuta - Imię": doc?.theraphist?.firstName,
      "Terapeuta - Nazwisko": doc?.theraphist?.lastName,
      "Terapeuta - ID": doc?.theraphist?.id,
      "Pacjent - ID": doc?.customer?.id,
      "Pacjent - Imię": doc?.customer?.firstName,
      "Pacjent - Nazwisko": doc?.customer?.lastName,
      "Wizyta - ID": doc?.appointment?.id,
      "Wizyta - Data i godzina": moment(doc?.appointment?.dateTime).format(
        "YYYY-MM-DD HH:mm"
      ),
      "Usługa - ID": doc?.appointment?.variant?.id,
      "Usługa - Nazwa": doc?.appointment?.variant?.name,
      "Usługa - Cena": doc?.appointment?.variant?.price,
      "Płatność dodana manualnie": doc?.isAddedManually ? "Tak" : "Nie",
      "Stworzone przez": doc?.isAddedManually ? `${doc?.createdBy?.lastName} ${doc?.createdBy?.firstName}` : "Automatycznie"
    }
  })
  return payments
}

export const addPayment = createAsyncThunk(
  "payments/addPayment",
  async ({ payment, currentUser }, { dispatch, getState }) => {
    try {
      await addDocWithUser(
        collection(db, "payments"),
        {
          ...payment
        },
        currentUser
      )
      return payment
    } catch (e) {
      console.error(e)
    }
  }
)

export const updatePayment = createAsyncThunk(
  "payments/updatePayment",
  async ({ payment, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "payments", payment.id),
        {
          ...payment
        },
        currentUser
      )
      return payment
    } catch (e) {
      console.error(e)
    }
  }
)

export const getPaymentsForDay = createAsyncThunk(
  "payments/getPaymentsForDay",
  async ({ date, theraphistId }) => {
    try {
      const q = query(
        collection(db, "payments"),
        where("theraphist.id", "==", theraphistId),
        date ? where("dateTime", ">=", moment(date).startOf("day").toDate()) : null,
        date ? where("dateTime", "<=", moment(date).endOf("day").toDate()) : null
      )
      const querySnapshot = await getDocs(q)
      const payments = []
      querySnapshot.forEach((doc) => {
        payments.push({
          id: doc.id,
          ...doc.data(),
          dateTime: doc.data().dateTime.toDate()
        })
      })
      return payments.filter(
        (payment) => payment.type !== "Przelewy24" && !payment.isDeleted
      )
    } catch (e) {
      console.error(e)
      return []
    }
  }
)

export const paymentsSlice = createSlice({
  name: "payments",
  initialState: {
    data: [],
    paymentsForDay: [],
    updatedAt: Date.now().toString()
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload
      store.updatedAt = Date.now().toString()
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentsForDay.fulfilled, (state, action) => {
      state.paymentsForDay = action.payload
      state.updatedAt = Date.now().toString()
    })
  }
})

export const subscribeToPayments = (start, end, userId, currentUser) => {
  if (currentUser && currentUser.role) {
    if (paymentsSubscription.sub) {
      if (
        start &&
        paymentsSubscription.start &&
        paymentsSubscription.start.getTime() !== start.getTime()
      ) {
        paymentsSubscription.sub()
      } else if (
        end &&
        paymentsSubscription.end &&
        paymentsSubscription.end.getTime() !== end.getTime()
      ) {
        paymentsSubscription.sub()
      } else if (paymentsSubscription.userId !== userId) {
        paymentsSubscription.sub()
      } else {
        return
      }
    }

    paymentsSubscription.loading = true

    paymentsSubscription.start = start
    paymentsSubscription.end = end
    paymentsSubscription.userId = userId
    const constrainspayments = []

    if (start) constrainspayments.push(where("dateTime", ">=", start))
    if (end) constrainspayments.push(where("dateTime", "<=", end))
    if (currentUser && currentUser.role !== "ADMIN") {
      constrainspayments.push(where("theraphist.id", "==", currentUser.id))
    } else if (userId) {
      constrainspayments.push(where("theraphist.id", "==", userId))
    }

    try {
      paymentsSubscription.sub = onSnapshot(
        query(collection(db, "payments"), ...constrainspayments),
        (data) => {
          const payments = []
          data.forEach((doc) => {
            payments.push({
              id: doc.id,
              ...doc.data(),
              dateTime: doc.data().dateTime.toDate(),
              appointment: doc.data().appointment ? {
                    ...doc.data().appointment,
                    dateTime: doc.data().appointment?.dateTime.toDate()
                  } : null
            })
          })
          store.dispatch(
            paymentsSlice.actions.setData(
              orderBy(
                payments.filter((p) => !p.isDeleted),
                "dateTime",
                "desc"
              )
            )
          )
          paymentsSubscription.loading = false
          return payments
        }
      )
    } catch (e) {
      paymentsSubscription.loading = false
      console.error(e)
    }
  }
}
export default paymentsSlice.reducer
