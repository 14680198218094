import moment from "moment-timezone"

export const mapAvailabilityExcel = (list) => {
  const mappedList = []
  list.forEach((item) => {
    mappedList.push({
      ID: item.id,
      "Data od": moment(item.startDate).format("YYYY-MM-DD"),
      "Data do": moment(item.endDate).format("YYYY-MM-DD"),
      "Godzina od": moment(item.startHour).format("HH:mm"),
      "Godzina do": moment(item.endHour).format("HH:mm"),
      Typ: item.type?.label,
      Opis: item.description,
      "Lokalizacja - ID": item.location?.id,
      "Lokalizacja - Nazwa": item.location?.name,
      "Gabinet - ID": item.room?.name,
      "Gabinet - Nazwa": item.room?.name,
      "Terapeuta - ID": item?.theraphist?.id,
      "Terapeuta - Imię": item?.theraphist?.firstName,
      "Terapeuta - Nazwisko": item?.theraphist?.lastName,
      "Dzień tygodnia": item.dayOfWeek?.value,
      Komentarz: item.theraphist.comment,
      "Terapeuta ukryty z kalendarza": item.theraphist.isHiddenFromCalendar ? "Tak" : "Nie"
    })
  })
  return mappedList
}

export const mapAvailability = (firebaseData) => {
  const availability = Boolean(firebaseData.data) ? firebaseData.data() : firebaseData
  try {
    const mappedAvail = {
      id: firebaseData.id,
      startDate: availability.startDate?.toDate(),
      endDate: availability.endDate?.toDate(),
      startHour: availability.startHour?.toDate(),
      endHour: availability.endHour?.toDate(),
      type: availability.type ? availability.type : {
            value: "WORKING_HOURS",
            label: "Godziny pracy"
          },
      isDeleted: availability.isDeleted,
      description: availability.description,
      isDeleted: availability.isDeleted,
      calendarLabel: availability.calendarLabel,
      theraphist: {
        id: availability.theraphist.id,
        firstName: availability.theraphist.firstName,
        lastName: availability.theraphist.lastName,
        avatar:
          availability.theraphist.avatar ||
          "https://firebasestorage.googleapis.com/v0/b/psychocare-prod.appspot.com/o/DALL%C2%B7E%202023-12-12%2018.19.27%20-%20A%20gender-neutral%20avatar%20with%20a%20futuristic%20and%20abstract%20design%2C%20similar%20to%20the%20previous%20image%20but%20with%20the%20face%20looking%20straight%20at%20the%20camera.%20The%20ava.png?alt=media&token=b0b13390-9b2f-47b4-a617-234d4ffc5a5e",
        comment: availability.theraphist.comment || "",
        isHiddenFromCalendar: Boolean(
          availability.theraphist.isHiddenFromCalendar
        )
      },
      dayOfWeek: availability.dayOfWeek,
      location: availability.location?.id ? {
            id: availability.location?.id,
            name: availability.location?.name
          } : null,
      room: availability.room?.id ? {
            id: availability.room?.id,
            name: availability.room?.name
          } : null
    }
    return mappedAvail
  } catch (e) {
    console.error("Error mapping avail", firebaseData, firebaseData.id)
  }
  return false
}
