// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { sortBy } from "lodash"
import moment from "moment-timezone"
import { db } from "../configs/firebase"
import { mapAvailability } from "./model/availability"
import { store } from "./store"
// ** Axios Imports

const availabilitySub = {
  sub: null,
  start: null
}

let notesSub = null

const userAvailabilitySub = {
  start: null,
  end: null,
  sub: null,
  userId: null
}

export const availabilitySlice = createSlice({
  name: "availability",
  initialState: {
    notesRaw: [],
    notes: [],
    data: [],
    userAvailability: [],
    updatedAt: Date.now().toString()
  },
  reducers: {
    setAvailabilityData: (state, action) => {
      state.data = action.payload
      store.updatedAt = Date.now().toString()
    },
    setNotesData: (state, action) => {
      state.notesRaw = action.payload.notesRaw
      state.notes = action.payload.notes
    },
    setUserAvailabilityData: (state, action) => {
      state.userAvailability = action.payload
      store.updatedAt = Date.now().toString()
    }
  }
})

export const subscribeToAvailability = (start, locationId) => {
  if (availabilitySub.sub) {
    if (
      availabilitySub.start !== start ||
      availabilitySub.locationId !== locationId
    ) {
      availabilitySub.sub()
    } else {
      return
    }
  }
  availabilitySub.start = start
  availabilitySub.locationId = locationId

  try {
    const constrains = [where("isDeleted", "==", false)]

    if (locationId) constrains.push(where("location.id", "==", locationId))

    availabilitySub.sub = onSnapshot(
      query(collection(db, "availability"), ...constrains),
      (data) => {
        const availabilityData = []
        data.forEach((doc) => {
          availabilityData.push(mapAvailability(doc))
        })
        const startMoment = moment(start).subtract(10, "minutes")
        const filteredAvailability = availabilityData.filter((a) => {
          return !a.isDeleted && start ? moment(a.endDate).isSameOrAfter(startMoment) : true
        })
        store.dispatch(
          availabilitySlice.actions.setAvailabilityData(filteredAvailability)
        )
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export const subscribeToUserAvailability = (start, end, userId) => {
  if (userAvailabilitySub.sub) {
    if (
      userAvailabilitySub.start !== start ||
      userAvailabilitySub.end !== end ||
      userAvailabilitySub.userId !== userId
    ) {
      userAvailabilitySub.sub()
    } else {
      return
    }
  }
  userAvailabilitySub.userId = userId
  userAvailabilitySub.start = start
  userAvailabilitySub.end = end

  try {
    const constrains = [where("isDeleted", "==", false)]
    constrains.push(where("theraphist.id", "==", userId))

    userAvailabilitySub.sub = onSnapshot(
      query(collection(db, "availability"), ...constrains),
      (data) => {
        const userAvailabilityData = []
        data.forEach((doc) => {
          userAvailabilityData.push({
            id: doc.id,
            ...doc.data(),
            startDate: moment(doc.data().startDate?.toDate()).toDate(),
            endDate: moment(doc.data().endDate?.toDate()).toDate(),
            startHour: Array.isArray(doc.data().startHour) ? moment(doc.data().startHour[0].toDate()).toDate() : doc.data().startHour ? moment(doc.data().startHour.toDate()).toDate() : null,
            endHour: Array.isArray(doc.data().endHour) ? moment(doc.data().endHour[0].toDate()).toDate() : doc.data().endHour ? moment(doc.data().endHour.toDate()).toDate() : null,
            type: doc.data().type ? doc.data().type : {
                  value: "WORKING_HOURS",
                  label: "Godziny pracy"
                }
          })
        })
        const filteredAvailability = userAvailabilityData.filter((a) => {
          return (
            !a.isDeleted &&
            (Boolean(a.type && a.type.value !== "WORKING_HOURS") ? true : (start ? moment(a.endDate).isSameOrAfter(start) : true) &&
                (end ? moment(a.startDate).isSameOrBefore(end) : true))
          )
        })
        store.dispatch(
          availabilitySlice.actions.setUserAvailabilityData(
            sortBy(filteredAvailability, "dayOfWeek.value")
          )
        )
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export const subscribeToNotes = () => {
    if (!notesSub) {
    const constrainsAvailability = []
    constrainsAvailability.push(where("isDeleted", "==", false))
    notesSub = onSnapshot(
      query(collection(db, "availabilityNotes"), ...constrainsAvailability),
      (data) => {
        try {
          store.dispatch(
            availabilitySlice.actions.setNotesData({
              notesRaw: data.docs.map((d) => ({ id: d.id, ...d.data() })),
              notes: data.docs.map((d) => ({
                id: d.id,
                resourceId: d.data().userId,
                title: `${d.data().note}`,
                type: "NOTE",
                backgroundColor: "antiquewhite",
                borderColor: "lightgrey",
                fontSize: 5,
                allDay: true,
                start: d.data().singleDay ? moment(d.data().displayDate.toDate())
                      .startOf("day")
                      .toDate() : false,
                end: d.data().singleDay ? moment(d.data().displayDate.toDate()).endOf("day").toDate() : false,
                startRecur: !d.data().singleDay,
                endRecur: !d.data().singleDay,
                daysOfWeek: d.data().singleDay ? false : Object.keys(d.data()).reduce((acc, key) => {
                      if (
                        d.data()[key] === true &&
                        key.indexOf("dayOfWeek") > -1
                      ) {
                        acc.push(Number(key.replace("dayOfWeek", "")) % 7)
                      }
                      return acc
                    }, [])
              }))
            })
          )
        } catch (e) {
          console.error(e)
        }
      }
    )
  }
}

export default availabilitySlice.reducer
