// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where
} from "firebase/firestore"
import { orderBy } from "lodash"
import { addDocWithUser, db, updateDocWithUser } from "../configs/firebase"
import { store } from "./store"
// ** Axios Imports

let locationsSubscription = null

export const getLocation = createAsyncThunk(
  "locations/getLocation",
  async (id) => {
    const docRef = doc(db, "locations", id)
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  }
)

export const addLocation = createAsyncThunk(
  "locations/addLocation",
  async ({ location, currentUser }, { dispatch, getState }) => {
    await addDocWithUser(
      collection(db, "locations"),
      {
        ...location
      },
      currentUser
    )
    return location
  }
)

export const updateLocation = createAsyncThunk(
  "locations/updateLocation",
  async ({ location, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "locations", location.id),
        {
          ...location
        },
        currentUser
      )
      return location
    } catch (e) {
      console.error(e)
    }
  }
)

export const locationsSlice = createSlice({
  name: "locations",
  initialState: {
    data: [],
    selectedLocation: null,
    updatedAt: Date.now().toString()
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload
      state.updatedAt = Date.now().toString()
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLocation.fulfilled, (state, action) => {
      state.selectedLocation = action.payload
    })
  }
})

export const subscribeToLocations = () => {
  if (locationsSubscription) {
    return
  }
  try {
    locationsSubscription = onSnapshot(
      query(collection(db, "locations"), where("isDeleted", "!=", true)),
      (data) => {
        const locations = []
        data.forEach((doc) => {
          locations.push({
            id: doc.id,
            ...doc.data()
          })
        })
        store.dispatch(
          locationsSlice.actions.setData(orderBy(locations, ["name"], ["asc"]))
        )
        return locations
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export default locationsSlice.reducer
