// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where
} from "firebase/firestore"
import { orderBy } from "lodash"
import moment from "moment-timezone"
import { addDocWithUser, db, updateDocWithUser } from "../configs/firebase"
import { mapAppointment } from "./model/appointment"
import { store } from "./store"

// ** Axios Imports

export const appointmentsSubscription = {
  sub: null,
  start: null,
  end: null,
  selectedTheraphist: null,
  userId: null,
  loading: false,
  status: ''
}

const appointmentsMyCalendarSubscription = {
  sub: null,
  start: null,
  end: null,
  userId: null
}

const invoiceData = {
  sub: null,
  userId: null,
  loading: false
}

export const addAppointment = createAsyncThunk(
  "appointments/addAppointment",
  async ({ appointment, currentUser }, { dispatch, getState }) => {
    try {
      await addDocWithUser(
        collection(db, "appointments"),
        {
          ...appointment,
          appointmentStatus: "SCHEDULED",
          paymentType: "ONLINE",
          paymentStatus: "PENDING",
          isNotificationProcessed: false,
          isDeleted: false
        },
        currentUser
      )
      return appointment
    } catch (e) {
      console.error(e)
    }
  }
)

export const updateAppointment = createAsyncThunk(
  "appointments/updateAppointment",
  async ({ appointment, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "appointments", appointment.id),
        {
          ...appointment
        },
        currentUser
      )
      return appointment
    } catch (e) {
      console.error(e)
    }
  }
)

export const getAppointementsForTheraphist = createAsyncThunk(
  "appointments/getAppointementsForTheraphist",
  async (id) => {
    try {
      const q = query(
        collection(db, "appointments"),
        where("theraphist.id", "==", id)
      )
      const querySnapshot = await getDocs(q)
      const appointments = []
      querySnapshot.forEach((doc) => {
        if (doc.data().appointmentType?.key !== "RESERVATION") {
          appointments.push(mapAppointment({ id: doc.id, ...doc.data() }))
        }
      })

      const q2 = query(
        collection(db, "customers"),
        where(`assignedUsersIdMap.${id}`, "==", true)
      )
      const querySnapshot2 = await getDocs(q2)
      const customers = []
      querySnapshot2.forEach((doc) => {
        customers.push({ id: doc.id, ...doc.data() })
      })
      return { appointments, customers }
    } catch (e) {
      console.error(e)
      return []
    }
  }
)

export const getAppointementsForCustomer = createAsyncThunk(
  "appointments/getAppointementsForCustomer",
  async (id) => {
    try {
      const q = query(
        collection(db, "appointments"),
        where("customer.id", "==", id),
        where("isDeleted", "!=", true)
      )
      const querySnapshot = await getDocs(q)
      const appointments = []
      querySnapshot.forEach((doc) => {
        appointments.push(mapAppointment({ id: doc.id, ...doc.data(), dateTimeApp: doc.data().dateTime.toDate() }))
      })

      return _.orderBy(appointments, ['dateTime', 'desc'])
    } catch (e) {
      console.error(e)
      return []
    }
  }
)

export const getAppointmentsForUserForDate = createAsyncThunk(
  "appointments/getAppointmentsForUserForDate",
  async ({ userId, date }) => {
    try {
      const q = query(
        collection(db, "appointments"),
        where("theraphist.id", "==", userId),
        where("dateTime", ">=", moment(date).startOf("day").toDate()),
        where("dateTime", "<=", moment(date).endOf("day").toDate())
      )
      const querySnapshot = await getDocs(q)
      const appointments = []
      querySnapshot.forEach((doc) => {
        if (!doc.data().isDeleted) {
          appointments.push(
            mapAppointment({
              id: doc.id,
              ...doc.data(),
              dateTime: doc.data().dateTime.toDate()
            })
          )
        }
      })
      return appointments
    } catch (e) {
      console.error(e)
      return []
    }
  }
)

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState: {
    dataAppointments: [],
    dataMyCalendar: [],
    userAppointments: [],
    updatedAt: Date.now().toString(),
    selectedAppointment: null,
    selectedAppointementForCustomer: [],
    selectedAppointementForTheraphist: [],
    selectedCustomersForTheraphist: [],
    appointmentsForUserForDate: [],
    invoiceData: []
  },
  reducers: {
    setData(state, action) {
      state.dataAppointments = action.payload
      store.updatedAt = Date.now().toString()
    },
    setDataMyCalendar(state, action) {
      state.dataMyCalendar = action.payload
      store.updatedAt = Date.now().toString()
    },
    setDataForUser(state, action) {
      state.userAppointments = action.payload
      store.updatedAt = Date.now().toString()
    },
    setMonthInvoiceForUser(state, action) {
      state.invoiceData = action.payload
      store.updatedAt = Date.now().toString()
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppointementsForCustomer.fulfilled, (state, action) => {
        state.selectedAppointementForCustomer = action.payload
      })
      .addCase(getAppointementsForTheraphist.fulfilled, (state, action) => {
        state.selectedAppointementForTheraphist = action.payload.appointments
        state.selectedCustomersForTheraphist = action.payload.customers
      })
      .addCase(getAppointmentsForUserForDate.fulfilled, (state, action) => {
        state.appointmentsForUserForDate = action.payload
        store.updatedAt = Date.now().toString()
      })
  }
})

export const subscribeToAppointments = (
  start,
  end,
  theraphistId,
  user,
  status
) => {
  appointmentsSubscription.loading = true
  if (user && user.role) {
    if (appointmentsSubscription.sub) {
      if (
        start &&
        appointmentsSubscription.start &&
        appointmentsSubscription.start.getTime() !== start.getTime()
      ) {
        appointmentsSubscription.sub()
      } else if (
        end &&
        appointmentsSubscription.end &&
        appointmentsSubscription.end.getTime() !== end.getTime()
      ) {
        appointmentsSubscription.sub()
      } else if (
        (theraphistId &&
          appointmentsSubscription.theraphistId !== theraphistId) ||
        typeof theraphistId === "boolean"
      ) {
        appointmentsSubscription.sub()
      } else if (user && appointmentsSubscription.userId !== user.id) {
        appointmentsSubscription.sub()
      } else if (appointmentsSubscription.status !== status) {
        appointmentsSubscription.sub()
      } else {
        appointmentsSubscription.loading = false
        return
      }
    }
    store.dispatch(appointmentsSlice.actions.setData([]))
    appointmentsSubscription.start = start
    appointmentsSubscription.end = end
    appointmentsSubscription.theraphistId = theraphistId
    appointmentsSubscription.userId = user?.id
    const constrainsAppointments = status === 'deleted' ? [where("isDeleted", "==", true)] : [where("isDeleted", "==", false)]

    if (start) constrainsAppointments.push(where("dateTime", ">=", start))
    if (end) constrainsAppointments.push(where("dateTime", "<=", end))
    if (user && user.role !== "ADMIN") {
      constrainsAppointments.push(where("theraphist.id", "==", user.id))
    } else if (theraphistId) {
      constrainsAppointments.push(where("theraphist.id", "==", theraphistId))
    }

    try {
      appointmentsSubscription.sub = onSnapshot(
        query(collection(db, "appointments"), ...constrainsAppointments),
        (data) => {
          const appointments = []
          data.forEach((doc) => {
            if (doc.data().appointmentType?.key !== "RESERVATION") {
              appointments.push(
                mapAppointment({
                  id: doc.id,
                  ...doc.data(),
                  dateTime: doc.data().dateTime.toDate()
                })
              )
            }
          })
          store.dispatch(
            appointmentsSlice.actions.setData(orderBy(appointments, "dateTime"))
          )
          appointmentsSubscription.loading = false
          return appointments
        }
      )
    } catch (e) {
      console.error(e)
    }
  }
}

export const subscribeToAppointmentsMyCalendar = (start, end, user) => {
  if (appointmentsMyCalendarSubscription.sub) {
    if (
      start &&
      appointmentsMyCalendarSubscription.start &&
      appointmentsMyCalendarSubscription.start.getTime() !== start.getTime()
    ) {
      appointmentsMyCalendarSubscription.sub()
    } else if (
      end &&
      appointmentsMyCalendarSubscription.end &&
      appointmentsMyCalendarSubscription.end.getTime() !== end.getTime()
    ) {
      appointmentsMyCalendarSubscription.sub()
    } else if (user && appointmentsMyCalendarSubscription.userId !== user.id) {
      appointmentsMyCalendarSubscription.sub()
    } else {
      return
    }
  }
  appointmentsMyCalendarSubscription.start = start
  appointmentsMyCalendarSubscription.end = end
  appointmentsMyCalendarSubscription.userId = user?.id
  const constrainsAppointments = [where("isDeleted", "==", false)]

  if (start) constrainsAppointments.push(where("dateTime", ">=", start))
  if (end) constrainsAppointments.push(where("dateTime", "<=", end))
  constrainsAppointments.push(where("theraphist.id", "==", user.id))

  try {
    appointmentsMyCalendarSubscription.sub = onSnapshot(
      query(collection(db, "appointments"), ...constrainsAppointments),
      (data) => {
        const appointments = []
        data.forEach((doc) => {
          appointments.push(
            mapAppointment({
              id: doc.id,
              ...doc.data(),
              dateTime: moment(doc.data().dateTime.toDate()).toDate()
            })
          )
        })
        store.dispatch(
          appointmentsSlice.actions.setDataMyCalendar(
            orderBy(appointments, "dateTime")
          )
        )
        return appointments
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export const subscribeToMonthInvoiceForUser = (userId, month) => {
  if (invoiceData.sub) {
    if (userId && invoiceData.userId && invoiceData.userId !== userId) {
      invoiceData.sub()
    } else if (month && invoiceData.month && invoiceData.month !== month) {
      invoiceData.sub()
    } else {
      return
    }
  }
  invoiceData.userId = userId
  invoiceData.month = month
  const constrainsAppointments = [where("isDeleted", "==", false)]

  if (userId) constrainsAppointments.push(where("theraphist.id", "==", userId))
  if (month) constrainsAppointments.push(where("invoiceMonth", "==", month))

  try {
    invoiceData.sub = onSnapshot(
      query(collection(db, "appointments"), ...constrainsAppointments),
      (data) => {
        const appointments = []
        data.forEach((doc) => {
          if (doc.data().appointmentType?.key !== "RESERVATION") {
            appointments.push(
              mapAppointment({
                id: doc.id,
                ...doc.data(),
                dateTime: doc.data().dateTime.toDate()
              })
            )
          }
        })
        store.dispatch(
          appointmentsSlice.actions.setMonthInvoiceForUser(
            orderBy(appointments, "dateTime")
          )
        )
        return appointments
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export default appointmentsSlice.reducer
