// ** Redux Imports
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where
} from "firebase/firestore"
import { getFunctions, httpsCallable } from "firebase/functions"
import { sortBy } from "lodash"
import moment from "moment-timezone"
import { calendarWorker } from "../App"
import { app, db } from "../configs/firebase"
import { mapAppointment } from "./model/appointment"
import { mapAvailability } from "./model/availability"
import { store } from "./store"
// ** Axios Imports

export let appointmentsDataConfig = {
  appointmentsSub: null,
  appointmentStatus: null,
  availabilitySub: null,
  notesSub: null,
  startDateWeek: false,
  endDateWeek: false,
  selectedDateStart: false,
  selectedDateEnd: false,
  locationId: null,
  customerCategory: null,
  appointmentStatus: null,
  isAvailableStatus: null,
  theraphyTypes: [],
  theraphyTypesIds: [],
  customerProblemsIds: [],
  theraphistIds: [],
  theraphists: [],
  users: [],
  usersMap: {},
  customerCategory: null
}

export const getCurrentCalendarView = createAsyncThunk(
  "globalCalendar/getCalendarView",
  async (filter) => {
    try {
      const functions = getFunctions(app, "europe-central2")
      const GetCalendar = httpsCallable(functions, "GetCalendar")
      const data = await GetCalendar({
        dateTimeEnd: filter.endDateWeek,
        dateTimeStart: filter.startDateWeek,
        locationId: filter.location?.id || null,
        theraphistIds: filter.theraphists.map((t) => t.id)
      })
      return data
    } catch (e) {
      console.error(e)
      return { events: [], resources: [] }
    }
  }
)

export const getTheraphistInfo = createAsyncThunk(
  "globalCalendar/getTheraphistInfo",
  async (userId) => {
    const data = {
      customers: [],
      variants: []
    }
    try {
      const customersRef = query(
        collection(db, "customers"),
        where(`assignedUsersIdMap.${userId}`, "==", true)
      )
      const customersResponse = await getDocs(customersRef)
      customersResponse.forEach((e) => {
        if (!e.data().isDeleted) {
          data.customers.push({ id: e.id, ...e.data() })
        }
      })

      data.customers = sortBy(data.customers, (o) => o.lastName)

      const servicesRef = query(
        collection(db, "userServicesVariants"),
        where("userId", "==", userId),
        where("isDeleted", "!=", true)
      )
      const services = await getDocs(servicesRef)
      services.forEach((e) => data.variants.push({ id: e.id, ...e.data() }))
      data.variants.sort((a, b) => {
    // Access nested properties safely
    const getNestedProperty = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj)

    // Compare 'variant.isOnline', true first (you can invert the order if false should come first)
    if (getNestedProperty(a, 'variant.isOnline') === true && getNestedProperty(b, 'variant.isOnline') === false) {
        return 1
    }
    if (getNestedProperty(a, 'variant.isOnline') === false && getNestedProperty(b, 'variant.isOnline') === true) {
        return -1
    }

        if (window.location.href.indexOf('osrodek') > -1) {
    // Compare 'price' in ascending order
    if (a.price < b.price) {
        return 1
    }
    if (a.price > b.price) {
        return 1
    }          
        }

    // Compare 'service.name' in descending order
    // if (getNestedProperty(a, 'service.name') < getNestedProperty(b, 'service.name')) {
    //     return 1
    // }
    // if (getNestedProperty(a, 'service.name') > getNestedProperty(b, 'service.name')) {
    //     return -1
    // }
        
            if (getNestedProperty(a, 'variant.name') < getNestedProperty(b, 'variant.name')) {
        return 1
    }
    if (getNestedProperty(a, 'variant.name') > getNestedProperty(b, 'variant.name')) {
        return -1
    }

    return 0 // if all comparisons are equal
      })

      return data
    } catch (e) {
      console.error(e)
    }
  }
)

export const globalCalendarSlice = createSlice({
  name: "globalCalendar",
  initialState: {
    userServiceVariants: [],
    userServiceCustomers: [],
    appointmentsData: [],
    availabilityData: [],
    updatedAt: Date.now().toString(),
    notesData: [],
    isLoading: true,
    isAvailLoading: true,
    isAppointLoading: true,
    calendarData: {
      events: [],
      resources: [],
      resourcesToShowByLocation: []
    }
  },
  reducers: {
    regenerateView: (state, action) => {
      state.locationId = action.payload
      state.isLoading = true
      calendarWorker.postMessage(
        JSON.stringify({
          appointments: state.appointmentsData,
          availability: state.availabilityData,
          locationId: action.payload,
          config: appointmentsDataConfig
        })
      )
      state.updatedAt = Date.now().toString()
    },
    setLoading: (state) => {
      console.log("loading true")
      state.isLoading = true
    },
    setAppointmentsData: (state, action) => {
      state.appointmentsData = action.payload.appointments
      state.locationId = action.payload.locationId
      calendarWorker.postMessage(
        JSON.stringify({
          appointments: action.payload.appointments,
          availability: current(state.availabilityData),
          locationId: action.payload.locationId,
          config: appointmentsDataConfig
        })
      )
      state.updatedAt = Date.now().toString()
    },
    setCalendarData: (state, action) => {
      state.calendarData = action.payload.calendarData
      state.locationId = action.payload.locationId
      state.updatedAt = Date.now().toString()
      console.log("loading false")
      state.isLoading = false
    },
    setAvailabilityData: (state, action) => {
      state.availabilityData = action.payload.availability
      state.locationId = action.payload.locationId
      calendarWorker.postMessage(
        JSON.stringify({
          appointments: current(state.appointmentsData),
          availability: action.payload.availability,
          locationId: action.payload.locationId,
          config: appointmentsDataConfig
        })
      )
      state.updatedAt = Date.now().toString()
    },
    setNotesData: (state, action) => {
      state.notesData = action.payload.notes
      state.notesRaw = action.payload.notesRaw
      state.updatedAt = Date.now().toString()
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTheraphistInfo.fulfilled, (state, action) => {
        state.userServiceVariants = action.payload.variants
        state.userServiceCustomers = action.payload.customers
        state.updatedAt = Date.now().toString()
      })
      .addCase(getCurrentCalendarView.fulfilled, (state, action) => {
        state.calendarData = action.payload
        state.updatedAt = Date.now().toString()
      })
  }
})

export const subscribeToNotes = () => {
    if (!appointmentsDataConfig.notesSub) {
    const constrainsAvailability = []
    constrainsAvailability.push(where("isDeleted", "==", false))
    appointmentsDataConfig.notesSub = onSnapshot(
      query(collection(db, "calendarNotes"), ...constrainsAvailability),
      (data) => {
        try {
          store.dispatch(
            globalCalendarSlice.actions.setNotesData({
              notesRaw: data.docs.map((d) => ({ id: d.id, ...d.data() })),
              notes: data.docs.map((d) => ({
                id: d.id,
                resourceId: d.data().userId,
                title: `${d.data().note}`,
                type: "NOTE",
                backgroundColor: "antiquewhite",
                borderColor: "lightgrey",
                fontSize: 5,
                allDay: true,
                start: d.data().singleDay ? moment(d.data().displayDate.toDate())
                      .startOf("day")
                      .toDate() : false,
                end: d.data().singleDay ? moment(d.data().displayDate.toDate()).endOf("day").toDate() : false,
                startRecur: !d.data().singleDay,
                endRecur: !d.data().singleDay,
                daysOfWeek: d.data().singleDay ? false : Object.keys(d.data()).reduce((acc, key) => {
                      if (
                        d.data()[key] === true &&
                        key.indexOf("dayOfWeek") > -1
                      ) {
                        acc.push(Number(key.replace("dayOfWeek", "")) % 7)
                      }
                      return acc
                    }, [])
              }))
            })
          )
        } catch (e) {
          console.error(e)
        }
      }
    )
  }
}

export const subscribeToCurrentCalendarView = ({
  dateStart,
  dateEnd,
  location,
  theraphists,
  theraphyTypes,
  customerCategory,
  appointmentStatus,
  isAvailableStatus,
  customerProblems
}) => {
  console.log("sub try")
  const locationId = location?.id
  const theraphistIds = theraphists.map((t) => t.id)
  const theraphyTypesIds = theraphyTypes.map((t) => t.id)
  const customerProblemsIds = (customerProblems || []).map((t) => t.id)
  const startDateWeek = moment(dateStart).startOf("isoWeek").toDate()
  const endDateWeek = moment(dateEnd).endOf("isoWeek").toDate()
  const getMoreData =
    !moment(appointmentsDataConfig.startDateWeek).isSame(
      moment(startDateWeek)
    ) || !moment(appointmentsDataConfig.endDateWeek).isSame(moment(endDateWeek))
  const reinitiate =
    appointmentsDataConfig.locationId !== locationId ||
    JSON.stringify(theraphistIds) !==
      JSON.stringify(appointmentsDataConfig.theraphistIds) ||
    JSON.stringify(theraphyTypesIds) !==
      JSON.stringify(appointmentsDataConfig.theraphyTypesIds) ||
    JSON.stringify(customerProblemsIds) !==
      JSON.stringify(appointmentsDataConfig.customerProblemsIds) ||
    moment(appointmentsDataConfig.selectedDateStart) !== moment(dateStart) ||
    moment(appointmentsDataConfig.selectedDateEnd) !== moment(dateEnd)

  appointmentsDataConfig.selectedDateStart = moment(dateStart)
  appointmentsDataConfig.selectedDateEnd = moment(dateEnd)

  appointmentsDataConfig.theraphistIds.length = 0
  theraphistIds.forEach((id) => appointmentsDataConfig.theraphistIds.push(id))

  appointmentsDataConfig.theraphyTypes.length = 0
  theraphyTypes.forEach((id) => appointmentsDataConfig.theraphyTypes.push(id))

  appointmentsDataConfig.theraphyTypesIds.length = 0
  theraphyTypesIds.forEach((id) =>
    appointmentsDataConfig.theraphyTypesIds.push(id)
  )

  appointmentsDataConfig.customerProblemsIds.length = 0
  customerProblemsIds.forEach((id) =>
    appointmentsDataConfig.customerProblemsIds.push(id)
  )

  appointmentsDataConfig.customerCategory = customerCategory
  appointmentsDataConfig.appointmentStatus = appointmentStatus
  appointmentsDataConfig.isAvailableStatus = isAvailableStatus
  appointmentsDataConfig.usersMap = store
    .getState()
    .users.data.reduce((p, c) => {
      p[c.id] = {
        ...c,
        theraphyTypeIds: c.theraphyTypes ? c.theraphyTypes.map((t) => t.id) : [],
        customerProblemsIds: c.customerProblems ? c.customerProblems.map((t) => t.id) : []
      }

      return p
    }, {})
  appointmentsDataConfig.endDateWeek = endDateWeek
  appointmentsDataConfig.startDateWeek = startDateWeek
  appointmentsDataConfig.locationId = locationId
  appointmentsDataConfig.theraphists = theraphists

  if (getMoreData) {
    store.dispatch(globalCalendarSlice.actions.setLoading())
    appointmentsDataConfig.startDateWeek = startDateWeek
    appointmentsDataConfig.endDateWeek = endDateWeek
    if (appointmentsDataConfig.appointmentsSub) {
      appointmentsDataConfig.appointmentsSub()
    }
    const constrainsAppointments = [where("isDeleted", "==", false)]

    if (startDateWeek) constrainsAppointments.push(where("dateTime", ">=", startDateWeek))
    if (endDateWeek) constrainsAppointments.push(where("dateTime", "<=", endDateWeek))
    constrainsAppointments.push(orderBy("dateTime", "asc"))
    console.log(startDateWeek, endDateWeek)
    console.log("appointmentsDataConfig", appointmentsDataConfig)
    appointmentsDataConfig.appointmentsSub = onSnapshot(
      query(collection(db, "appointments"), ...constrainsAppointments),
      (data) => {
        try {
          store.dispatch(
            globalCalendarSlice.actions.setAppointmentsData({
              appointments: data.docs.map((doc) => {
                return mapAppointment({
                  id: doc.id,
                  ...doc.data(),
                  dateTime: doc.data().dateTime.toDate()
                })
              }),
              locationId: appointmentsDataConfig.locationId
            })
          )
        } catch (e) {
          console.error(e)
        }
      }
    )
  } else if (reinitiate) {
    store.dispatch(globalCalendarSlice.actions.setLoading())
    store.dispatch(globalCalendarSlice.actions.regenerateView(locationId))
  }

  if (!appointmentsDataConfig.availabilitySub) {
    const constrainsAvailability = []
    constrainsAvailability.push(where("isDeleted", "==", false))

    appointmentsDataConfig.availabilitySub = onSnapshot(
      query(collection(db, "availability"), ...constrainsAvailability),
      (data) => {
        try {
          store.dispatch(
            globalCalendarSlice.actions.setAvailabilityData({
              availability: data.docs.map((d) => mapAvailability(d)),
              locationId: appointmentsDataConfig.locationId
            })
          )
        } catch (e) {
          console.error(e)
        }
      }
    )
  }

subscribeToNotes()
}

export const unsubscribeFromCurrentCalendarView = () => {
  if (appointmentsDataConfig.appointmentsSub) {
    appointmentsDataConfig.appointmentsSub()
    appointmentsDataConfig.appointmentsSub = false
  }
  if (appointmentsDataConfig.availabilitySub) {
    appointmentsDataConfig.availabilitySub()
    appointmentsDataConfig.availabilitySub = false
  }
  appointmentsDataConfig = {
    appointmentsSub: null,
    appointmentStatus: null,
    availabilitySub: null,
    notesSub: null,
    startDateWeek: false,
    endDateWeek: false,
    selectedDateStart: false,
    selectedDateEnd: false,
    locationId: null,
    customerCategory: null,
    appointmentStatus: null,
    isAvailableStatus: null,
    theraphyTypes: [],
    theraphyTypesIds: [],
    customerProblemsIds: [],
    theraphistIds: [],
    theraphists: [],
    users: [],
    usersMap: {},
    customerCategory: null
  }
}

export default globalCalendarSlice.reducer
