import moment from "moment-timezone"

const roomModel = {
  customer: {},
  location: {},
  room: {},
  theraphist: {},
  variant: {},
  dateTime: Date.now(),

  repeatType: "", //One Time, Daily, Weekly, Monthly
  repeats: null,

  appointmentStatus: "", //SCHEDULED, COMPLETED, CANCELED,
  cancelReason: "",
  dateChangeReason: "",

  paymentToken: "",
  paymentTokenCreatedAt: "",

  paymentStatus: "", //PENDING, COMPLETED_AUTOMATICALLY, COMPLETED_MANUALLY
  paymentStatusUpdatedAt: "",
  paymentStatusUpdatedBy: "",

  paymentType: "", //OFFLINE, BANK_TRANSFER, CREDIT_CARD, CASH, N/A
  paymentTypeUpdatedAt: "",
  paymentTypeUpdatedBy: "CUSTOMER",

  updatedAt: "",
  updatedBy: {},

  createdBy: "",
  createdAt: ""
}

export const mapAppointment = (firebaseData) => {
  const appointmentData = firebaseData
  const status = appointmentData.appointmentStatus
  return {
    id: firebaseData.id,
    automaticCancelIfNotPaid: appointmentData.automaticCancelIfNotPaid,
    automaticCancelIfNotPaidHours: appointmentData.automaticCancelIfNotPaidHours,
    automaticCancelDate: appointmentData.automaticCancelDate,
    appointmentToken: appointmentData.appointmentToken,
    appointmentStatus: status,
    appointmentType: appointmentData.appointmentType,
    invoiceMonth: appointmentData.invoiceMonth,
    cancelReason: appointmentData.cancelReason,
    parentId: appointmentData.parentId,
    createdAt: appointmentData.createdAt,
    customer: {
      id: appointmentData.customer.id,
      firstName: appointmentData.customer.firstName,
      lastName: appointmentData.customer.lastName,
      email: appointmentData.customer.email,
      phoneNumber: appointmentData.customer.phoneNumber,
      skype: appointmentData.customer.skype,
      contractSignDate: appointmentData.customer.contractSignDate,
      cancelledAppointments: appointmentData.customer.cancelledAppointments
    },
    comment: appointmentData.comment,
    dateChangeReason: appointmentData.dateChangeReason,
    dateTime: appointmentData.dateTime,
    isDeleted: appointmentData.isDeleted,
    location: {
      id: appointmentData.location.id,
      name: appointmentData.location.name,
      address: appointmentData.location.address
    },
    meetingUrl: appointmentData.meetingUrl,
    paymentDetails: appointmentData.paymentDetails,
    paymentStatus: appointmentData.paymentStatus,
    paymentToken: appointmentData.paymentToken,
    paymentType: appointmentData.paymentType,
    invoiceMonthClosed: appointmentData.invoiceMonthClosed,
    repeatType: appointmentData.repeatType,
    repeats: appointmentData.repeats,
    room: {
      id: appointmentData.room.id,
      name: appointmentData.room.name
    },
    theraphist: {
      id: appointmentData.theraphist.id,
      firstName: appointmentData.theraphist.firstName,
      lastName: appointmentData.theraphist.lastName,
      email: appointmentData.theraphist.email,
      avatar:
        appointmentData.theraphist.avatar ||
        "https://firebasestorage.googleapis.com/v0/b/psychocare-prod.appspot.com/o/DALL%C2%B7E%202023-12-12%2018.19.27%20-%20A%20gender-neutral%20avatar%20with%20a%20futuristic%20and%20abstract%20design%2C%20similar%20to%20the%20previous%20image%20but%20with%20the%20face%20looking%20straight%20at%20the%20camera.%20The%20ava.png?alt=media&token=b0b13390-9b2f-47b4-a617-234d4ffc5a5e"
    },
    updatedAt: appointmentData.updatedAt,
    updatedBy: {
      id: appointmentData.updatedBy?.id || "",
      firstName: appointmentData.updatedBy?.firstName || "",
      lastName: appointmentData.updatedBy?.lastName || ""
    },
        deletedAt: appointmentData.deletedAt?.toDate(),
    deletedBy: {
      id: appointmentData.deletedBy?.id || "",
      firstName: appointmentData.deletedBy?.firstName || "",
      lastName: appointmentData.deletedBy?.lastName || ""
    },
    variant: appointmentData.variant
  }
}

const translate = (type, v) => {
  const dictionary = {
    "Rodzaj płaności": {
      ONLINE: "Online",
      OFFLINE: "Stacjonarnie",
      BANK_TRANSFER: "Przelew bankowy",
      CREDIT_CARD: "Karta kredytowa",
      CARD: "Karta kredytowa",
      CASH: "Gotówka",
      Przelewy24: "Przelewy24",
      comment: "Komentarz terapeuty",
      "N/A": "N/A"
    },

    "Status płatności": {
      COMPLETED_AUTOMATICALLY: "Opłacono - automatycznie",
      COMPLETED_MANUALLY: "Opłacono - ręcznie",
      PENDING: "Oczekuje"
    },

    Status: {
      SCHEDULED: "Zaplanowana",
      COMPLETED: "Zrealizowana",
      CANCELED: "Odwołana",
      "CANCELED-PAID": "Odwołana poza terminem"
    },
    "Powód odwołania": {
      THERAPHIST: "Terapeuta odwołał",
      CUSTOMER: "Klient odwołał",
      HOLIDAYS: "Urlop terapeuty"
    },
    "Powód zmiany terminu": {
      CUSTOMER: "Odwołanie klienta",
      THERAPIST_ILLNESS: "Choroba terapeuty",
      THERAPIST_VACATION: "Urlop terapeuty",
      THERAPIST_CHANGE: "Przelozona przez terapeutę",
      THERAPY_RESIGNATION: "Rezygnacja z terapii",
      PROCESS_TERMINATION: "Zakończenie procesu",
      CUSTOMER_ABSENT: "Klient nie pojawił się na wizycie"
    }
  }

  return dictionary[type] ? dictionary[type][v] : v
}

export const mapAppointmentCSV = (firebaseData) => {
  const appointmentData = firebaseData
  const status = appointmentData.appointmentStatus
  let paymentType = ""
  if (appointmentData.paymentStatus === "PENDING") {
    paymentType = ""
  } else {
    if (appointmentData.paymentType === "Przelewy24") {
      paymentType = "Przelewy24"
    }
    if (appointmentData.paymentType === "ONLINE") {
      paymentType = "Przelewy24"
    }
    if (appointmentData.paymentType === "BANK_TRANSFER") {
      paymentType = "BANK_TRANSFER"
    }
    if (
      appointmentData.paymentType === "OFFLINE" &&
      appointmentData.paymentDetails
    ) {
      paymentType = appointmentData.paymentDetails.type
    }
  }

  return {
    id: firebaseData.id,
    Status: translate("Status", status),
    "Rodzaj wizyty": appointmentData.appointmentType ? appointmentData.appointmentType.value : "",
    "Powód odwołania": translate(
      "Powód odwołania",
      appointmentData.cancelReason
    ),
    "Pacjent - ID": appointmentData?.customer?.id,
    "Pacjent - Imię": appointmentData?.customer?.firstName,
    "Pacjent - Nazwisko": appointmentData?.customer?.lastName,
    Komentarz: appointmentData?.comment,
    "Powód zmiany daty": translate(
      "Powód zmiany terminy",
      appointmentData.dateChangeReason
    ),
    Data: moment(appointmentData.dateTime).format("YYYY-MM-DD HH:mm"),
    "Płatność - Status": translate(
      "Status płatności",
      appointmentData.paymentStatus
    ),
    "Płatność - Rodzaj": translate("Rodzaj płaności", paymentType),
    "Terapeuta - Imię": appointmentData.theraphist?.firstName,
    "Terapeuta - Nazwisko": appointmentData.theraphist?.lastName,
    "Terapeuta - ID": appointmentData.theraphist?.id,
    "Lokalizacja - id": appointmentData.location?.id,
    "Lokalizacja - Nazwa": appointmentData.location?.name,
    "Usługa - id": appointmentData.variant?.id,
    "Usługa - nazwa": appointmentData.variant?.name,
    "Usługa - cena": appointmentData.variant?.price,
    "Usługa - stawka": appointmentData.variant?.rate
  }
}
