// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { orderBy } from "lodash"
import { db } from "../configs/firebase"
import { store } from "./store"
// ** Axios Imports

let secretSub = null

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    secrets: [],
    secretsUser: [],
    updatedAt: Date.now().toString()
  },
  reducers: {
    setSecrets(state, action) {
      state.secrets = action.payload
      store.updatedAt = Date.now().toString()
    },
    setSecretsUser(state, action) {
      state.secretsUser = action.payload
      store.updatedAt = Date.now().toString()
    }
  }
})

export const subscribeToSecrets = (singleUser, userId) => {
  if (secretSub) {
    return
  }
  try {
    const queryObj = [where("isDeleted", "!=", true)]
    if (singleUser) {
      queryObj.push(where("userId", "==", userId))
    }
    secretSub = onSnapshot(
      query(collection(db, "secrets"), ...queryObj),
      (data) => {
        const secrets = []
        data.forEach((doc) => {
          secrets.push({
            id: doc.id,
            ...doc.data()
          })
        })
        if (singleUser) {
          store.dispatch(
            settingsSlice.actions.setSecretsUser(orderBy(secrets, "name"))
          )
        } else {
          store.dispatch(
            settingsSlice.actions.setSecrets(
              orderBy(secrets, "name").filter((s) => s.type === "GLOBAL")
            )
          )
        }
      }
    )
  } catch (e) {
    console.log(e)
    // console.error(e)
  }
}

export default settingsSlice.reducer
