import {
  Briefcase,
  Calendar,
  Grid,
  Home,
  Layers,
  List,
  MapPin,
  Settings,
  User,
  UserCheck
} from "react-feather"
import { BsQuestionCircle } from "react-icons/bs"
import { FaCashRegister, FaPlane } from "react-icons/fa"
import { TbFileInvoice } from "react-icons/tb"

export default [
  {
    id: "me",
    title: "Mój profil",
    requiredPermission: ["ADMIN", "THERAPHIST"],
    icon: <User size={20} />,
    navLink: "/me"
  },
  {
    id: "calendar",
    title: "Kalendarz",
    title: "Kalendarz",
    requiredPermission: ["ADMIN"],
    icon: <Calendar size={20} />,
    navLink: "/calendar"
  },
  // {
  //   id: "reports",
  //   title: "Raporty",
  //   requiredPermission: ["ADMIN"],
  //   icon: <MdAnalytics size={20} />,
  //   navLink: "/reports"
  // },
  {
    id: "myCalendar",
    title: "Mój Kalendarz",
    requiredPermission: ["THERAPHIST"],
    icon: <Calendar size={20} />,
    navLink: "/myCalendar"
  },
  {
    id: "appointments",
    title: "Wizyty",
    requiredPermission: ["ADMIN", "THERAPHIST"],
    icon: <Briefcase size={20} />,
    navLink: "/appointments"
  },
  {
    id: "availability",
    title: "Grafik",
    requiredPermission: ["ADMIN"],
    icon: <Grid size={20} />,
    navLink: "/availability"
  },
  {
    id: "payments",
    title: "Płatności",
    requiredPermission: ["ADMIN", "THERAPHIST"],
    icon: <FaCashRegister size={20} />,
    navLink: "/payments"
  },
  {
    id: "invoicing",
    title: "Rozliczenia",
    requiredPermission:
      !(window.location.href.indexOf('atmaia') > -1 || window.location.href.indexOf('osrodek') > -1) ? ["ADMIN", "THERAPHIST"] : ["ADMIN"],
    icon: <TbFileInvoice size={20} />,
    navLink: "/invoicing"
  },
  {
    id: "timeOff",
    title: "Urlopy",
    requiredPermission: ["ADMIN"],
    icon: <FaPlane size={20} />,
    navLink: "/timeOff"
  },
  {
    id: "faq",
    title: "FAQ",
    requiredPermission: ["ADMIN", "THERAPHIST"],
    icon: <BsQuestionCircle size={20} />,
    navLink: "/faq"
  },
  {
    id: "customers",
    title: "Klienci",
    requiredPermission: ["ADMIN", "THERAPHIST"],
    icon: <UserCheck size={20} />,
    navLink: "/customers"
  },
  {
    id: "generatedInvoices",
    title: "Faktury",
    requiredPermission: window.location.href.indexOf('atmaia') > -1 ? ["ADMIN", "THERAPHIST"] : ["ADMIN"],
    icon: <List size={20} />,
    navLink: "/generatedInvoices"
  },
  // {
  //   id: "statistics",
  //   title: "Statistics",
  //   icon: <BarChart2 size={20} />,
  //   navLink: "/statistics"
  // },
  // {
  //   id: "notifications",
  //   title: "Notifications",
  //   icon: <Bell size={20} />,
  //   navLink: "/notifications"
  // },
  {
    id: "settings",
    title: "Ustawienia",
    requiredPermission: ["ADMIN"],
    icon: <Settings size={20} />,
    children: [
      {
        id: "users",
        title: "Użytkownicy",
        requiredPermission: ["ADMIN"],
        icon: <User size={20} />,
        navLink: "/users"
      },
      {
        id: "locations",
        title: "Lokalizacje",
        requiredPermission: ["ADMIN"],
        icon: <MapPin size={20} />,
        navLink: "/locations"
      },
      {
        id: "rooms",
        title: "Gabinety",
        requiredPermission: ["ADMIN"],
        icon: <Home size={20} />,
        navLink: "/rooms"
      },
      {
        id: "services",
        title: "Usługi",
        requiredPermission: ["ADMIN"],
        icon: <Layers size={20} />,
        navLink: "/services"
      },
      {
        id: "theraphyTypes",
        title: (window.location.href.indexOf('atmaia') > -1) ? "Certyfikaty" : "Nurty terapeutyczne",
        requiredPermission: ["ADMIN"],
        icon: <List size={20} />,
        navLink: "/theraphyTypes"
      },
      {
        id: "customerSources",
        title: "Źródła klientów",
        requiredPermission: ["ADMIN"],
        icon: <List size={20} />,
        navLink: "/customerSources"
      },
      {
        id: "customerProblems",
        title: "Problematyka klientów",
        requiredPermission: ["ADMIN"],
        icon: <List size={20} />,
        navLink: "/customerProblems"
      },
      {
        id: "settings",
        title: "System",
        requiredPermission: ["ADMIN"],
        icon: <List size={20} />,
        navLink: "/settings"
      }
    ]
  }
]
