// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import {
  addDoc,
  doc,
  enableIndexedDbPersistence,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc
} from "firebase/firestore"
import { getStorage } from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
		apiKey: "AIzaSyDw6SxVwtUjvUezrhP1oHmC8kC8NCxHdVg",
		authDomain: "inspo-prod-68d7c.firebaseapp.com",
		projectId: "inspo-prod-68d7c",
		storageBucket: "inspo-prod-68d7c.appspot.com",
		messagingSenderId: "189025485854",
		appId: "1:189025485854:web:81174e3a543081b3b0a889",
		measurementId: "G-3MVH4NRDWS"
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const auth = getAuth(app)
enableIndexedDbPersistence(db, { synchronizeTabs: true })
export const updateDocWithUser = (reference, data, user) => {
  return updateDoc(reference, {
    ...data,
    updatedBy: {
      id: user.userData.id,
      firstName: user.userData.firstName,
      lastName: user.userData.lastName,
      avatar:
        user.userData.avatar ||
        "https://firebasestorage.googleapis.com/v0/b/psychocare-prod.appspot.com/o/DALL%C2%B7E%202023-12-12%2018.19.27%20-%20A%20gender-neutral%20avatar%20with%20a%20futuristic%20and%20abstract%20design%2C%20similar%20to%20the%20previous%20image%20but%20with%20the%20face%20looking%20straight%20at%20the%20camera.%20The%20ava.png?alt=media&token=b0b13390-9b2f-47b4-a617-234d4ffc5a5e"
    },
    updatedAt: serverTimestamp()
  })
  // }
}

export const setDocWithUser = (reference, data, user) => {
  return setDoc(reference, {
    ...data,
    updatedBy: {
      id: user.userData.id,
      firstName: user.userData.firstName,
      lastName: user.userData.lastName
    },
    updatedAt: serverTimestamp()
  })
}

export const addDocWithUser = (reference, data, user) => {
  return addDoc(reference, {
    ...data,
    isDeleted: false,
    createdBy: {
      id: user.userData.id,
      firstName: user.userData.firstName,
      lastName: user.userData.lastName,
      avatar:
        user.userData.avatar ||
        "https://firebasestorage.googleapis.com/v0/b/psychocare-prod.appspot.com/o/DALL%C2%B7E%202023-12-12%2018.19.27%20-%20A%20gender-neutral%20avatar%20with%20a%20futuristic%20and%20abstract%20design%2C%20similar%20to%20the%20previous%20image%20but%20with%20the%20face%20looking%20straight%20at%20the%20camera.%20The%20ava.png?alt=media&token=b0b13390-9b2f-47b4-a617-234d4ffc5a5e"
    },
    createdAt: serverTimestamp()
  })
}

export const deleteDocument = (collectionName, docId, user) => {
  return updateDoc(doc(db, collectionName, docId), {
    isDeleted: true,
    deletedBy: {
      id: user.userData.id,
      firstName: user.userData.firstName,
      lastName: user.userData.lastName
    },
    deletedAt: serverTimestamp()
  })
}
