import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut
} from "firebase/auth"
import { doc, getDoc } from "firebase/firestore"
import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"
// ** Redux Imports
import * as Sentry from "@sentry/react"
import { getAnalytics, setUserId } from "firebase/analytics"
import { auth, db } from "../configs/firebase.js"
import moment from "moment-timezone"

const analytics = getAnalytics()

export const AuthContext = React.createContext()

export let isAuthed = false

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUser] = React.useState({
    auth: null,
    userData: null,
    error: null,
    loading: false
  })
  const location = useLocation()
  React.useEffect(() => {
    const authSub = onAuthStateChanged(auth, async (user) => {
      if (user) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "login",
          userId: user.uid
        })
        setUserId(analytics, user.uid)
        const docRef = doc(db, "users", user.uid)
        const docSnap = await getDoc(docRef)
        const userData = { id: user.uid, ...docSnap.data() }
        Sentry.setUser({ email: user.email })
        isAuthed = true
        if (userData?.timeZone) {
          moment.tz.setDefault(userData?.timeZone)
        }
        setUser({ authData: user, userData, error: null, loading: false })
        if (userData.role === "ADMIN") {
          if (
            location.pathname === "/" ||
            location.pathname === "/login" ||
            location.pathname === "/resetPassword"
          ) {
            navigate("/me")
          } else {
            // navigate(location.pathname, { search: location.search })
          }
        } else {
          if (
            location.pathname === "/" ||
            location.pathname === "/login" ||
            location.pathname === "/resetPassword"
          ) {
            navigate("/myCalendar")
          } else {
            if (location.pathname === "/register") {
              
            } else {
              navigate(location.pathname, { search: location.search })
            }
          }
        }
      } else {
        isAuthed = false
        setUser({ authData: null, userData: null, error: null, loading: false })
        if (
          location.pathname === "/forgot-password" ||
          location.pathname === "/register" ||
          location.pathname === "/resetPassword" ||
          location.pathname === "/paymentType" ||
          location.pathname === "/start" ||
          location.pathname === "/paymentConfirmation" ||
          location.pathname === "/payment" ||
          location.pathname === "/trainingSignUp" ||
          location.pathname === "/gdpr"
        ) {
        } else {
          navigate("/", { replace: false, search: location.search })
        }
      }
    })
    return authSub
  }, [])

  const login = async ({ email, password }) => {
    setUser({
      authData: null,
      userData: null,
      error: null,
      loading: true
    })
    try {
      const res = await signInWithEmailAndPassword(auth, email, password)
    } catch (e) {
      let errorMessage = ""
      if (e?.code === "auth/user-not-found") {
        errorMessage = "User with this email has not been found."
      } else if (e?.code === "auth/invalid-password") {
        errorMessage = "Invalid password."
      } else if (e?.code === "auth/too-many-requests") {
        errorMessage =
          "Too many attempts. Reset your password or try again in few minutes."
      } else {
        errorMessage = "There was problem with login."
      }
      console.error(errorMessage)

      setUser({
        authData: null,
        userData: null,
        error: errorMessage,
        loading: false
      })
      console.error("Error while loggin in.", e)
    } finally {
    }
  }

  const logout = () => {
    signOut(auth)
  }

  const refreshSelf = async () => {
      const docRef = doc(db, "users", user.authData.uid)
      const docSnap = await getDoc(docRef)
    const userData = { id: user.authData.uid, ...docSnap.data() }
      if (userData?.timeZone) {
        moment.tz.setDefault(userData?.timeZone)
      }
      setUser({...user, userData})
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, refreshSelf }}>
      {children}
    </AuthContext.Provider>
  )
}
