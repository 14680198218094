// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { sortBy } from "lodash"
import moment from "moment-timezone"
import { db } from "../configs/firebase"
import { store } from "./store"
// ** Axios Imports

const timeOffSub = {
  sub: null,
  start: null
}

const usertimeOffSub = {
  start: null,
  end: null,
  sub: null,
  userId: null
}

export const timeOffSlice = createSlice({
  name: "timeOff",
  initialState: {
    data: [],
    userTimeOff: [],
    updatedAt: Date.now().toString()
  },
  reducers: {
    setTimeOffData: (state, action) => {
      state.data = action.payload
      store.updatedAt = Date.now().toString()
    },
    setUsertimeOffData: (state, action) => {
      state.userTimeOff = action.payload
      store.updatedAt = Date.now().toString()
    }
  }
})

export const subscribeTotimeOff = (start) => {
  if (timeOffSub.sub) {
    if (timeOffSub.start !== start) {
      timeOffSub.sub()
    } else {
      return
    }
  }
  timeOffSub.start = start

  try {
    const constrains = [
      where("isDeleted", "==", false),
      where("location.id", "==", "")
    ]

    if (start) constrains.push(where("startDate", "<=", start))

    timeOffSub.sub = onSnapshot(
      query(collection(db, "availability"), ...constrains),
      (data) => {
        const timeOffData = []
        data.forEach((doc) => {
          timeOffData.push({
            id: doc.id,
            theraphist: doc.data().theraphist,
            startDate: doc.data().startDate.toDate(),
            endDate: doc.data().endDate.toDate(),
            location: {
              id: ""
            }
          })
        })
        const filteredtimeOff = timeOffData.filter((a) => {
          return (
            !a.isDeleted &&
            (start ? moment(a.endDate).isSameOrAfter(start) : true)
          )
        })
        store.dispatch(timeOffSlice.actions.setTimeOffData(filteredtimeOff))
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export const subscribeToUsertimeOff = (start, end, userId) => {
  if (usertimeOffSub.sub) {
    if (
      usertimeOffSub.start !== start ||
      usertimeOffSub.end !== end ||
      usertimeOffSub.userId !== userId
    ) {
      usertimeOffSub.sub()
    } else {
      return
    }
  }
  usertimeOffSub.userId = userId
  usertimeOffSub.start = start
  usertimeOffSub.end = end

  try {
    const constrains = [
      where("isDeleted", "==", false),
      where("location.id", "==", "")
    ]
    constrains.push(where("theraphist.id", "==", userId))

    usertimeOffSub.sub = onSnapshot(
      query(collection(db, "availability"), ...constrains),
      (data) => {
        const usertimeOffData = []
        data.forEach((doc) => {
          usertimeOffData.push({
            id: doc.id,
            theraphist: doc.data().theraphist,
            startDate: doc.data().startDate.toDate(),
            endDate: doc.data().endDate.toDate(),
            location: {
              id: ""
            }
          })
        })
        const filteredtimeOff = usertimeOffData.filter((a) => {
          return (
            !a.isDeleted &&
            (start ? moment(a.endDate).isSameOrAfter(start) : true) &&
            (end ? moment(a.startDate).isSameOrBefore(end) : true)
          )
        })
        store.dispatch(
          timeOffSlice.actions.setUsertimeOffData(
            sortBy(filteredtimeOff, "startDate")
          )
        )
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export default timeOffSlice.reducer
