// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where
} from "firebase/firestore"
import { sortBy } from "lodash"
import { addDocWithUser, db, updateDocWithUser } from "../configs/firebase"
import { store } from "./store"
// ** Axios Imports

let servicesVariantsSubscription = null

export const getServiceVariant = createAsyncThunk(
  "servicesVariants/getServiceVariant",
  async (id) => {
    const docRef = doc(db, "servicesVariants", id)
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  }
)

export const addServiceVariant = createAsyncThunk(
  "servicesVariants/addServiceVariant",
  async ({ data, currentUser }, { dispatch, getState }) => {
    try {
      await addDocWithUser(
        collection(db, "servicesVariants"),
        {
          ...data
        },
        currentUser
      )
    } catch (e) {
      console.error(e)
    }
  }
)

export const updateServiceVariant = createAsyncThunk(
  "servicesVariants/updateServiceVariant",
  async ({ serviceVariant, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "servicesVariants", serviceVariant.id),
        {
          ...serviceVariant
        },
        currentUser
      )
      return serviceVariant
    } catch (e) {
      console.error(e)
    }
  }
)

export const deleteServiceVariant = createAsyncThunk(
  "servicesVariants/deleteServiceVariant",
  async (id, { dispatch, getState }) => {
    //   await axios.delete('/apps/servicesVariants/delete', { id })
    await dispatch(getData(getState().servicesVariants.params))
    return id
  }
)

export const servicesVariantsSlice = createSlice({
  name: "servicesVariants",
  initialState: {
    data: [],
    selectedServiceVariant: null,
    updatedAt: Date.now().toString()
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload
      state.updatedAt = Date.now().toString()
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceVariant.fulfilled, (state, action) => {
      state.selectedServiceVariant = action.payload
    })
  }
})

export const subscribeToServiceVariants = () => {
  if (servicesVariantsSubscription) {
    return
  }
  try {
    servicesVariantsSubscription = onSnapshot(
      query(collection(db, "servicesVariants"), where("isDeleted", "!=", true)),
      (data) => {
        const servicesVariants = []
        data.forEach((doc) => {
          servicesVariants.push({
            id: doc.id,
            ...doc.data()
          })
        })

        store.dispatch(
          servicesVariantsSlice.actions.setData(
            sortBy(servicesVariants, "name")
          )
        )
        return servicesVariants
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export default servicesVariantsSlice.reducer
