// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where
} from "firebase/firestore"
import { getFunctions, httpsCallable } from "firebase/functions"
import { orderBy } from "lodash"
import { app, db, updateDocWithUser } from "../configs/firebase"
import { store } from "./store"
// ** Axios Imports

let userSubscription = null

export const getUser = createAsyncThunk("user/getUser", async (id) => {
  const docRef = doc(db, "users", id)
  const docSnap = await getDoc(docRef)
  return { ...docSnap.data(), id }
})

export const clearUser = createAsyncThunk("user/clearUser", async () => {
  return true
})

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ data, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "users", data.id),
        {
          ...data
        },
        currentUser
      )
      dispatch(getUser(data.id))
      return data
    } catch (e) {
      console.error(e)
    }
  }
)

export const sendResetPassword = createAsyncThunk(
  "user/sendResetPassword",
  async (email, { dispatch, getState }) => {
    try {
      const functions = getFunctions(app, "europe-central2")
      const Users_sendResetPassword = httpsCallable(
        functions,
        "Users_sendResetPassword"
      )
      await Users_sendResetPassword({ email })
    } catch (e) {
      console.error(e)
    }
  }
)

export const updateUserAvtar = createAsyncThunk(
  "user/updateUserAvtar",
  async (data, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "users", data.id),
        {
          avatar: data.url
        },
        data.currentUser
      )
      dispatch(getUser(data.id))
    } catch (e) {
      console.error(e)
    }
  }
)

export const updateUserTimeZone = createAsyncThunk(
  "user/updateUserAvtar",
  async (data, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "users", data.id),
        {
          timeZone: data.timeZone
        },
        data.currentUser
      )
      dispatch(getUser(data.id))
    } catch (e) {
      console.error(e)
    }
  }
)

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (id, { dispatch, getState }) => {
    //   await axios.delete('/apps/user/delete', { id })
    await dispatch(getData(getState().user.params))
    return id
  }
)

export const userSlice = createSlice({
  name: "users",
  initialState: {
    data: [],
    selectedUser: null
  },
  reducers: {
    setData(state, action) {
      state.data.length = 0
      state.data.push(...action.payload)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.selectedUser = action.payload
    })
    builder.addCase(clearUser.fulfilled, (state, action) => {
      state.selectedUser = false
    })
  }
})

export const subscribeToUsers = () => {
  if (userSubscription) {
    return
  }
  try {
    userSubscription = onSnapshot(
      query(collection(db, "users"), where("isDeleted", "!=", true)),
      (data) => {
        const users = []
        data.forEach((doc) => {
          users.push({
            id: doc.id,
            ...doc.data(),
            avatar:
              doc.data().avatar ||
              "https://firebasestorage.googleapis.com/v0/b/psychocare-prod.appspot.com/o/DALL%C2%B7E%202023-12-12%2018.19.27%20-%20A%20gender-neutral%20avatar%20with%20a%20futuristic%20and%20abstract%20design%2C%20similar%20to%20the%20previous%20image%20but%20with%20the%20face%20looking%20straight%20at%20the%20camera.%20The%20ava.png?alt=media&token=b0b13390-9b2f-47b4-a617-234d4ffc5a5e"
          })
        })
        store.dispatch(
          userSlice.actions.setData(
            orderBy(users, ["lastName", "firstName"], ["asc"])
          )
        )
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export default userSlice.reducer
