// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { collection, doc, getDoc, getDocs } from "firebase/firestore"
import { addDocWithUser, db } from "../configs/firebase"
// ** Axios Imports

export const getAllData = createAsyncThunk(
  "notifications/getAllData",
  async () => {
    const colRef = collection(db, "notifications")
    const response = await getDocs(colRef)
    const data = []
    response.forEach((e) => data.push({ id: e.id, ...e.data() }))
    return data
  }
)

export const getData = createAsyncThunk(
  "notifications/getData",
  async (params) => {
    const colRef = collection(db, "notifications")
    const response = await getDocs(colRef)
    const data = []
    response.forEach((e) => data.push({ id: e.id, ...e.data() }))
    return {
      params,
      data,
      totalPages: response.data.total
    }
  }
)

export const getNotification = createAsyncThunk(
  "notifications/getNotification",
  async (id) => {
    const docRef = doc(db, "notifications", id)
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  }
)

export const addNotification = createAsyncThunk(
  "notifications/addNotification",
  async ({ data, currentUser }, { dispatch, getState }) => {
    const notification = {
      appointementId: data.id,
      type: data.type,
      ...data
    }
    await addDocWithUser(
      collection(db, "notifications"),
      notification,
      currentUser
    )
    return data
  }
)

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedNotification: null,
    stats: {
      admins: 0,
      theraphists: 0
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.selectedNotification = action.payload
      })
  }
})

export default notificationsSlice.reducer
