// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import { orderBy } from "lodash"
import { addDocWithUser, db, updateDocWithUser } from "../configs/firebase"
import { store } from "./store"
// ** Axios Imports

let roomsSubscription = null

export const addRoom = createAsyncThunk(
  "rooms/addRoom",
  async ({ room, currentUser }, { dispatch, getState }) => {
    await addDocWithUser(
      collection(db, "rooms"),
      {
        ...room
      },
      currentUser
    )
    return room
  }
)

export const updateRoom = createAsyncThunk(
  "rooms/updateRoom",
  async ({ room, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "rooms", room.id),
        {
          ...room
        },
        currentUser
      )
      return room
    } catch (e) {
      console.error(e)
    }
  }
)

export const roomsSlice = createSlice({
  name: "rooms",
  initialState: {
    data: [],
    updatedAt: Date.now().toString()
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload
      store.updatedAt = Date.now().toString()
    }
  }
})

export const subscribeToRooms = () => {
  if (roomsSubscription) {
    return
  }
  try {
    roomsSubscription = onSnapshot(
      query(collection(db, "rooms"), where("isDeleted", "!=", true)),
      (data) => {
        const rooms = []
        data.forEach((doc) => {
          rooms.push({
            id: doc.id,
            ...doc.data()
          })
        })
        store.dispatch(
          roomsSlice.actions.setData(
            orderBy(rooms, ["location.name", "name"], ["asc"])
          )
        )
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export default roomsSlice.reducer
