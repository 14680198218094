// ** React Imports
import { Link } from "react-router-dom"
// ** Custom Components
import Avatar from "@components/avatar"
import { Feedback, captureMessage, getClient } from "@sentry/react"
// ** Third Party Components
import { Power } from "react-feather"

// ** Reactstrap Imports
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"

// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/portrait/small/avatar-s-11.jpg"
import { useContext, useState } from "react"
import { useDispatch } from "react-redux"
import { AuthContext } from "../../../../context/auth"
import { GoGlobe } from "react-icons/go"
import { ChangeTimeZoneModal } from "../../../../components/changeTimezoneModal/index"
const UserDropdown = () => {
  const { logout, user, refreshSelf } = useContext(AuthContext)
  const client = getClient()
  const [isOpen, setIsOpen] = useState(false)
  const feedback = client?.getIntegration(Feedback)
  const toggle = () => {
    setIsOpen(!isOpen)
    setTimeout(() => refreshSelf(), 400)
  }
  return (
    <>
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">
            {user?.userData?.firstName} {user?.userData?.lastName}
          </span>
          <span className="user-status">
            {user?.userData?.role === "ADMIN" ? "Admin" : "Terapeuta"}
          </span>
        </div>
        {user?.userData?.avatar ? (
          <Avatar
            img={
              user?.userData?.avatar ? user?.userData?.avatar : defaultAvatar
            }
            isCustom
            imgHeight="40"
            imgWidth="40"
          />
        ) : (
          <Avatar
            initials
            imgHeight="40"
            imgWidth="40"
            content={`${user?.userData?.firstName} ${user?.userData?.lastName}`}
          />
        )}
      </DropdownToggle>
      <DropdownMenu end container={"body"}>
        <DropdownItem
          onClick={() =>
            captureMessage(`User ${user?.userData?.id} send logs`) &&
            feedback.openDialog()
          }
        >
          <Power size={14} className="me-75" />
          <span className="align-middle">Zgłoś problem</span>
        </DropdownItem>
                {/* <DropdownItem onClick={() => toggle()}>
          <GoGlobe size={14} className="me-75" />
          <span className="align-middle">Zmień strefę czasową</span>
        </DropdownItem> */}
        <DropdownItem tag={Link} onClick={() => logout()}>
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    <ChangeTimeZoneModal
    
      isOpened={isOpen}
      toggle={toggle}
        user={user}
      />
      </>
  )
}

export default UserDropdown
