// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import { orderBy } from "lodash"
import { addDocWithUser, db, updateDocWithUser } from "../configs/firebase"
import { store } from "./store"
// ** Axios Imports

let notesSubscriptionPrivate, notesSubscriptionPublic = null

export const addNote = createAsyncThunk(
  "notes/addNote",
  async ({ note, currentUser }, { dispatch, getState }) => {
    await addDocWithUser(
      collection(db, "notes"),
      {
        ...note
      },
      currentUser
    )
    return note
  }
)

export const updateNote = createAsyncThunk(
  "notes/updateNote",
  async ({ note, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "notes", note.id),
        {
          ...note
        },
        currentUser
      )
      return note
    } catch (e) {
      console.error(e)
    }
  }
)

export const notesSlice = createSlice({
  name: "notes",
  initialState: {
    publicData: [],
    privateData: [],
    updatedAt: Date.now().toString()
  },
  reducers: {
    setPublicData(state, action) {
      state.publicData = action.payload
      store.updatedAt = Date.now().toString()
    },
    setPrivateData(state, action) {
      state.privateData = action.payload
      store.updatedAt = Date.now().toString()
    },
    clearData(state, action) {
      state.publicData = []
      state.privateData = []
      store.updatedAt = Date.now().toString()
    }
  }
})

export const subscribeToNotes = (documentId, userId) => {
  store.dispatch(notesSlice.actions.clearData())
  if (notesSubscriptionPrivate) {
    notesSubscriptionPrivate()
  }
  if (
    notesSubscriptionPublic
  ) {
    notesSubscriptionPublic()
  }
  console.log(documentId, userId)
    notesSubscriptionPrivate = onSnapshot(
      query(collection(db, "notes"),
        where("documentId", "==", documentId),
        where('createdBy.id', '==', userId),
        where('isPublic', '==', false),
        where('isDeleted', '==', false)),
      (data) => {
        const notes = []

        data.forEach((doc) => {
          notes.push({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : null
          })
        })
                console.log('private', notes)
        store.dispatch(
          notesSlice.actions.setPublicData(orderBy(notes, ["createdAt"], ["asc"]))
        )
      }
    )

    notesSubscriptionPublic = onSnapshot(
    query(collection(db, "notes"),
      where("documentId", "==", documentId),
      where('isPublic', '==', true),
      where('isDeleted', '==', false)),
    (data) => {
      const notes = []
      data.forEach((doc) => {
        notes.push({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : null
        })
      })
      console.log('public', notes)
      store.dispatch(
        notesSlice.actions.setPrivateData(orderBy(notes, ["createdAt"], ["asc"]))
      )
    }
  )

}

export default notesSlice.reducer
