// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import { orderBy } from "lodash"
import { addDocWithUser, db, updateDocWithUser } from "../configs/firebase"
import { store } from "./store"
// ** Axios Imports

let theraphyTypesSubscription = null

export const addTheraphyType = createAsyncThunk(
  "theraphyTypes/addTheraphyTypes",
  async ({ data, currentUser }, { dispatch, getState }) => {
    await addDocWithUser(
      collection(db, "theraphyTypes"),
      {
        ...data
      },
      currentUser
    )
    return data
  }
)

export const updateTheraphyType = createAsyncThunk(
  "theraphyTypes/updateTheraphyTypes",
  async ({ data, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "theraphyTypes", data.id),
        {
          ...data
        },
        currentUser
      )
    } catch (e) {
      console.error(e)
    }
  }
)

export const deleteTheraphyType = createAsyncThunk(
  "theraphyTypes/deleteTheraphyTypes",
  async (id, { dispatch, getState }) => {
    //   await axios.delete('/apps/theraphyTypes/delete', { id })
    await dispatch(getData(getState().theraphyTypes.params))
    return id
  }
)

export const theraphyTypesSlice = createSlice({
  name: "theraphyTypes",
  initialState: {
    data: [],
    updatedAt: Date.now().toString()
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload
      store.updatedAt = Date.now().toString()
    }
  }
})

export const subscribeToTheraphyTypes = () => {
  if (theraphyTypesSubscription) {
    return
  }
  try {
    theraphyTypesSubscription = onSnapshot(
      query(collection(db, "theraphyTypes"), where("isDeleted", "!=", true)),
      (data) => {
        const theraphyTypes = []
        data.forEach((doc) => {
          theraphyTypes.push({
            id: doc.id,
            ...doc.data()
          })
        })
        const sorted = orderBy(theraphyTypes, ["name"], ["asc"])
        store.dispatch(theraphyTypesSlice.actions.setData(sorted))
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export default theraphyTypesSlice.reducer
