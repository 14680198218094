// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { orderBy } from "lodash"
import { db } from "../configs/firebase"
import { store } from "./store"

// ** Axios Imports

const invoiceData = {
  sub: null,
  loading: false,
  userId: null
}

export const invoicesSlice = createSlice({
  name: "generatedInvoices",
  initialState: {
    updatedAt: Date.now().toString(),
    data: []
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload
      store.updatedAt = Date.now().toString()
    }
  }
})

export const subscribeToInvoices = (
  start,
  end,
  theraphistId,
  user,
  includeDeleted
) => {
  invoiceData.loading = true
  if (user && user.role) {
    if (invoiceData.sub) {
      if (
        start &&
        invoiceData.start &&
        invoiceData.start.getTime() !== start.getTime()
      ) {
        invoiceData.sub()
      } else if (
        end &&
        invoiceData.end &&
        invoiceData.end.getTime() !== end.getTime()
      ) {
        invoiceData.sub()
      } else if (
        (theraphistId && invoiceData.theraphistId !== theraphistId) ||
        typeof theraphistId === "boolean"
      ) {
        invoiceData.sub()
      } else if (user && invoiceData.userId !== user.id) {
        invoiceData.sub()
      } else {
        invoiceData.loading = false
        return
      }
    }
    store.dispatch(invoicesSlice.actions.setData([]))
    invoiceData.start = start
    invoiceData.end = end
    invoiceData.theraphistId = theraphistId
    invoiceData.userId = user?.id
    const constrainsAppointments = false ? [] : [where("isDeleted", "==", false)]

    if (start) constrainsAppointments.push(where("createdAt", ">=", start))
    if (end) constrainsAppointments.push(where("createdAt", "<=", end))
    if (user && user.role !== "ADMIN") {
      constrainsAppointments.push(where("theraphist.id", "==", user.id))
    } else if (theraphistId) {
      constrainsAppointments.push(where("theraphist.id", "==", theraphistId))
    }

    try {
      invoiceData.sub = onSnapshot(
        query(
          collection(db, "generatedCustomerInvoices"),
          ...constrainsAppointments
        ),
        (data) => {
          const invoices = []
          data.forEach((doc) => {
            invoices.push({
              id: doc.id,
              ...doc.data(),
              dateTime: doc.data().createdAt?.toDate()
            })
          })
          store.dispatch(
            invoicesSlice.actions.setData(orderBy(invoices, "dateTime", "desc"))
          )
          invoiceData.loading = false
          return invoices
        }
      )
    } catch (e) {
      console.error(e)
    }
  }
}

export default invoicesSlice.reducer
