// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { collection, doc, onSnapshot, query } from "firebase/firestore"
import { orderBy } from "lodash"
import { addDocWithUser, db, updateDocWithUser } from "../configs/firebase"
import { store } from "./store"
// ** Axios Imports

let tenantSub = null
let templatesSub = null
const emailSub = null

export const updateTenant = createAsyncThunk(
  "tenant/updateTenant",
  async ({ tenant, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "tenants", tenant.docId),
        {
          ...tenant
        },
        currentUser
      )
      return tenant
    } catch (e) {
      console.error(e)
    }
  }
)

export const addTenant = createAsyncThunk(
  "tenant/addTenant",
  async ({ tenant, currentUser }, { dispatch, getState }) => {
    try {
      await addDocWithUser(
        collection(db, "tenants"),
        {
          ...tenant,
          isDeleted: false
        },
        currentUser
      )
      return tenant
    } catch (e) {
      console.error(e)
    }
  }
)

export const tenantSlice = createSlice({
  name: "tenant",
  initialState: {
    data: null,
    templates: [],
    tenantUpdatedAt: null
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload
      state.tenantUpdatedAt = new Date().toString()
    },
    setTemplates(state, action) {
      state.templates = action.payload
      state.tenantUpdatedAt = new Date().toString()
    }
  }
})

export const subscribeToTenant = () => {
  if (tenantSub || templatesSub) {
    return
  }
  tenantSub = onSnapshot(
    query(
      collection(db, "tenants")
      // where(
      // "baseUrl",
      // "==",
      // window.location.hostname !== "localhost" ? window.location.hostname : "panel-dev.psychocare.pl"
      // )
    ),
    (data) => {
      if (data.empty) {
        return
      }
      if (data.docs.length === 1) {
        store.dispatch(
          tenantSlice.actions.setData({
            ...data.docs[0].data(),
            docId: data.docs[0].id
          })
        )
      }
    }
  )

  templatesSub = onSnapshot(
    query(
      collection(db, "tenantTemplates")
      // where(
      // "url",
      // "==",
      // window.location.hostname !== "localhost" ? window.location.hostname : "panel-dev.psychocare.pl"
      // )
    ),
    (data) => {
      const templates = []
      data.forEach((doc) => {
        templates.push({
          id: doc.id,
          ...doc.data()
        })
      })
      store.dispatch(
        tenantSlice.actions.setTemplates(orderBy(templates, ["label"], ["asc"]))
      )
    }
  )
}

export default tenantSlice.reducer
