import React, { Suspense, useEffect, useState } from "react"
// ** Router Import
import moment from "moment-timezone"
import ReactGA from "react-ga4"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { firebaseConfig } from "./configs/firebase"
import { globalCalendarSlice } from "./redux/globalCalendar"
import Router from "./router/Router"

export let calendarWorker = null

ReactGA.initialize(firebaseConfig.measurementId)
moment.tz.setDefault("Europe/Warsaw")
const App = () => {
  const dispatch = useDispatch()
  if (window.Worker) {
    // Create a new Web Worker
    calendarWorker = new Worker(`${window.location.origin}/calendarWorker.js`)
    // Listen for messages from the worker
    calendarWorker.addEventListener("message", (event) => {
      // Dispatch action with worker's data
      const processedData = event.data
      dispatch(globalCalendarSlice.actions.setCalendarData(event.data))
    })

    // Listen for errors
    calendarWorker.addEventListener("error", function (error) {
      console.error("Error in Web Worker:", error)
    })
  }
  const location = useLocation()
  const [previousPath, setPreviousPath] = useState("")
  useEffect(() => {
    if (previousPath !== location.pathname) {
      setPreviousPath(location.pathname)
      ReactGA.send({ hitType: "pageview", page: location.pathname })
    }
  }, [location.pathname, previousPath])

  return (
    <Suspense fallback={null}>
      <Router />
    </Suspense>
  )
}

export default App
