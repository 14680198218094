// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where
} from "firebase/firestore"
import { getFunctions, httpsCallable } from "firebase/functions"
import { sortBy } from "lodash"
import { addDocWithUser, app, db, updateDocWithUser } from "../configs/firebase"

import { store } from "./store"

export const customersUserSubscription = {
  sub: null,
  userId: null,
  loading: true
}

export const getCustomersPaginated = createAsyncThunk(
  "customers/getCustomersPaginated",
  async ({ searchTerm, selectedPage = 0 }) => {
    try {
      const functions = getFunctions(app, "europe-central2")
      const Customers_getPaginated = httpsCallable(
        functions,
        "Customers_getPaginated"
      )
      const result = await Customers_getPaginated({ searchTerm, selectedPage })
      return {
        data: result.data.data,
        customersPaginationInformation:
          result.data.customersPaginationInformation
      }
    } catch (e) {
      console.error(e)
      return { data: [], customersPaginationInformation: { size: 0, pages: 0 } }
    }
  }
)

export const getCustomer = createAsyncThunk(
  "customers/getCustomer",
  async (id) => {
    const docRef = doc(db, "customers", id)
    const docSnap = await getDoc(docRef)
    return { ...docSnap.data(), id }
  }
)

export const addCustomer = createAsyncThunk(
  "customers/addCustomer",
  async ({ customer, currentUser }, { dispatch, getState }) => {
    try {
      await addDocWithUser(
        collection(db, "customers"),
        {
          ...customer
        },
        currentUser
      )
      return customer
    } catch (e) {
      console.error(e)
    }
  }
)

export const updateCustomer = createAsyncThunk(
  "customers/updateCustomer",
  async ({ customer, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "customers", customer.id),
        {
          ...customer
        },
        currentUser
      )
      return customer
    } catch (e) {
      console.error(e)
    }
  }
)

export const customersSlice = createSlice({
  name: "customers",
  initialState: {
    data: [],
    selectedCustomer: null,
    selectedCustomerLoading: false,
    customersPaginationInformation: {
      size: 0,
      pages: 0
    },
    customers: [],
    customersForUser: [],
    customersPaginated: [],
    updatedAt: Date.now().toString()
  },
  reducers: {
    setCustomersForUser(state, action) {
      state.customersForUser = action.payload
      state.updatedAt = Date.now().toString()
    },
    setCustomers(state, action) {
      state.customers = action.payload
      state.updatedAt = Date.now().toString()
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomer.pending, (state, action) => {
      state.selectedCustomer = null
      state.updatedAt = Date.now().toString()
      state.selectedCustomerLoading = true
    })
    builder.addCase(getCustomer.fulfilled, (state, action) => {
      state.selectedCustomer = action.payload
      state.updatedAt = Date.now().toString()
      state.selectedCustomerLoading = false
    })
    builder.addCase(getCustomersPaginated.fulfilled, (state, action) => {
      state.customersPaginated = action.payload.data
      state.customersPaginationInformation =
        action.payload.customersPaginationInformation
      state.updatedAt = Date.now().toString()
    })
  }
})

export const subscribeToCustomersForUser = (userId) => {
  if (!userId) {
  return
  }
  if (customersUserSubscription.sub) {
    if (userId && customersUserSubscription.userId !== userId) {
      customersUserSubscription.sub()
      customersUserSubscription.userId = userId
    } else {
      return
    }
  }
  customersUserSubscription.loading = true
  try {
    
    customersUserSubscription.sub = onSnapshot(
      query(
        collection(db, "customers"),
        where(`assignedUsersIdMap.${userId}`, "==", true)
      ),
      (data) => {
        const customers = []
        data.forEach((doc) => {
          customers.push({
            id: doc.id,
            ...doc.data()
          })
        })
        store.dispatch(customersSlice.actions.setCustomersForUser(customers))
        customersUserSubscription.loading = false
      }
    )
  } catch (e) {
    customersUserSubscription.loading = false
    console.error(e)
  }
}

export const subscribeToCustomers = (role) => {
  if (customersUserSubscription.sub) {
    return
  }
  customersUserSubscription.loading = true
  try {
    customersUserSubscription.sub = onSnapshot(
      query(collection(db, "customers"), where(`isDeleted`, "==", false)),
      (data) => {
        const customers = []
        data.forEach((doc) => {
          customers.push({
            id: doc.id,
            ...doc.data()
          })
        })
        store.dispatch(
          customersSlice.actions.setCustomers(
            sortBy(customers, ["lastName", "firstName"], ["asc", "asc"])
          )
        )
        customersUserSubscription.loading = false
      }
    )
  } catch (e) {
    customersUserSubscription.loading = false
    console.error(e)
  }
}

export default customersSlice.reducer
