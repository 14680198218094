// ** Reducers Imports
import appointmentDetails from "./appointmentDetails"
import appointments from "./appointments"
import availability from "./availability"
import customerProblems from "./customerProblems"
import customerSources from "./customerSources"
import customers from "./customers"
import globalCalendarNew from "./g1"
import globalCalendar from "./globalCalendar"
import generatedInvoices from "./generatedInvoices"
import invoices from "./invoices"
import layout from "./layout"
import locations from "./locations"
import navbar from "./navbar"
import notes from "./notes"
import notifications from "./notifications"
import payments from "./payments"
import rooms from "./rooms"
import services from "./services"
import servicesVariants from "./servicesVariants"
import settings from "./settings"
import tenant from "./tenant"
import theraphyTypes from "./theraphyTypes"
import timeOff from "./timeOff"
import trainings from "./trainings"
import users from "./users"

const rootReducer = {
  navbar,
  layout,
  users,
  notes,
  locations,
  services,
  rooms,
  appointments,
  customers,
  servicesVariants,
  payments,
  availability,
  theraphyTypes,
  notifications,
  globalCalendar,
  appointmentDetails,
  customerSources,
  trainings,
  timeOff,
  invoices,
  customerProblems,
  settings,
  generatedInvoices,
  tenant,
  globalCalendarNew
}

export default rootReducer
