// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import { orderBy } from "lodash"
import { addDocWithUser, db, updateDocWithUser } from "../configs/firebase"
import { store } from "./store"
// ** Axios Imports

let customerProblemsSubscription = null

export const addCustomerProblem = createAsyncThunk(
  "customerProblems/addCustomerProblems",
  async ({ data, currentUser }, { dispatch, getState }) => {
    await addDocWithUser(
      collection(db, "customerProblems"),
      {
        ...data
      },
      currentUser
    )
    return data
  }
)

export const updateCustomerProblem = createAsyncThunk(
  "customerProblems/updateCustomerProblems",
  async ({ data, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "customerProblems", data.id),
        {
          ...data
        },
        currentUser
      )
    } catch (e) {
      console.error(e)
    }
  }
)

export const deleteCustomerProblem = createAsyncThunk(
  "customerProblems/deleteCustomerProblems",
  async (id, { dispatch, getState }) => {
    //   await axios.delete('/apps/customerProblems/delete', { id })
    await dispatch(getData(getState().customerProblems.params))
    return id
  }
)

export const customerProblemsSlice = createSlice({
  name: "customerProblems",
  initialState: {
    data: [],
    updatedAt: Date.now().toString()
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload
      store.updatedAt = Date.now().toString()
    }
  }
})

export const subscribeToCustomerProblems = () => {
  if (customerProblemsSubscription) {
    return
  }
  try {
    customerProblemsSubscription = onSnapshot(
      query(collection(db, "customerProblems"), where("isDeleted", "!=", true)),
      (data) => {
        const customerProblems = []
        data.forEach((doc) => {
          customerProblems.push({
            id: doc.id,
            ...doc.data()
          })
        })
        const sorted = orderBy(customerProblems, ["name"], ["asc"])
        store.dispatch(customerProblemsSlice.actions.setData(sorted))
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export default customerProblemsSlice.reducer
