// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import { orderBy } from "lodash"
import { addDocWithUser, db, updateDocWithUser } from "../configs/firebase"
import { store } from "./store"
// ** Axios Imports

let customerSourcesSubscription = null

export const addCustomerSource = createAsyncThunk(
  "customerSources/addCustomerSource",
  async ({ customerSource, currentUser }, { dispatch, getState }) => {
    await addDocWithUser(
      collection(db, "customerSources"),
      {
        ...customerSource
      },
      currentUser
    )
    return customerSource
  }
)

export const updateCustomerSource = createAsyncThunk(
  "customerSources/updateCustomerSource",
  async ({ customerSource, currentUser }, { dispatch, getState }) => {
    try {
      await updateDocWithUser(
        doc(db, "customerSources", customerSource.id),
        {
          ...customerSource
        },
        currentUser
      )
      return customerSource
    } catch (e) {
      console.error(e)
    }
  }
)

export const customerSourcesSlice = createSlice({
  name: "customerSources",
  initialState: {
    data: [],
    updatedAt: Date.now().toString()
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload
      state.updatedAt = Date.now().toString()
    }
  }
})

export const subscribeToCustomerSources = () => {
  if (customerSourcesSubscription) {
    return
  }
  try {
    customerSourcesSubscription = onSnapshot(
      query(collection(db, "customerSources"), where("isDeleted", "!=", true)),
      (data) => {
        const customerSources = []
        data.forEach((doc) => {
          customerSources.push({
            id: doc.id,
            ...doc.data()
          })
        })
        store.dispatch(
          customerSourcesSlice.actions.setData(
            orderBy(customerSources, ["name"], ["asc"])
          )
        )
        return customerSources
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export default customerSourcesSlice.reducer
