// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { intersection, uniqBy } from "lodash"
import moment from "moment-timezone"
import { db } from "../configs/firebase"
import { store } from "./store"
// ** Axios Imports

const rawData = []

export const appointmentsDataConfig = {
  appointmentsSub: null,
  appointmentStatus: null,
  availabilitySub: null,
  weekNumber: false,
  locationId: null,
  customerCategory: null,
  appointmentStatus: null,
  isAvailableStatus: null,
  theraphyTypes: [],
  theraphyTypesIds: [],
  theraphistIds: [],
  theraphists: [],
  users: [],
  usersMap: {},
  customerCategory: null
}

export const getTheraphistInfo = createAsyncThunk(
  "globalCalendar/getTheraphistInfo",
  async (userId) => {
    const data = {
      customers: [],
      variants: []
    }
    try {
      const customersRef = query(
        collection(db, "customers"),
        where(`assignedUsersIdMap.${userId}`, "==", true)
      )
      const customersResponse = await getDocs(customersRef)
      customersResponse.forEach((e) => {
        if (!e.data().isDeleted) {
          data.customers.push({ id: e.id, ...e.data() })
        }
      })

      data.customers = sortBy(data.customers, (o) => o.lastName)

      const servicesRef = query(
        collection(db, "userServicesVariants"),
        where("userId", "==", userId),
        where("isDeleted", "!=", true)
      )
      const services = await getDocs(servicesRef)
      services.forEach((e) => data.variants.push({ id: e.id, ...e.data() }))

      data.variants = sortBy(data.variants, (o) => o.service.name)

      return data
    } catch (e) {
      console.error(e)
    }
  }
)

const generateCalendarView = (data, locationId) => {
  const filledResources = {}
  const resources = []
  const filteredData = data
    .map((d) => ({ id: d.id, ...d.data() }))
    .filter((c) => {
      if (appointmentsDataConfig.theraphistIds.length) {
        if (appointmentsDataConfig.theraphistIds.includes(c.userId)) {
        } else {
          return false
        }
      } else {
        if (appointmentsDataConfig.usersMap[c.userId]?.isHiddenFromCalendar) {
          return false
        }
      }

      if (appointmentsDataConfig.theraphyTypesIds.length) {
        if (
          intersection(
            appointmentsDataConfig.theraphyTypesIds,
            appointmentsDataConfig.usersMap[c.userId].theraphyTypeIds
          ).length
        ) {
        } else {
          return false
        }
      }

      return true
    })
    .reduce((p, c) => {
      //filter availability
      const availability = c.availability
        .filter((d) =>
          (appointmentsDataConfig.locationId ? appointmentsDataConfig.locationId === d.locationId : true)
        )
        .map((d) => {
          return {
            ...d,
            type: "availability",
            startRecur: d.startRecur.toDate(),
            endRecur: d.endRecur.toDate(),
            startTime: `${d.startTime.slice(0, 5)}`,
            endTime: `${d.endTime.slice(0, 5)}`,
            resourceId: c.userId,
            groupId: c.userId,
            display: "inverse-background",
            backgroundColor: "#B8B8B8"
          }
        })

      const isAvailable = availability.length
      if (isAvailable) {
        resources.push(c)
      }
      const timeOff = isAvailable ? c.timeOff
            .map((d) => {
              return {
                start: d.startDate?.toDate(),
                end: d.endDate?.toDate(),
                resourceId: c.userId,
                groupId: c.userId,
                display: "background",
                backgroundColor: "red",
                allDay: true
              }
            })
            .filter((a) => Boolean(a?.id)) : []

      const blocks = isAvailable ? c.blocks
            .map((d) => {
              return {
                title: d.title,
                startRecur: d.startRecur.toDate(),
                endRecur: d.endRecur.toDate(),
                daysOfWeek: d.daysOfWeek,
                startTime: `${d.startTime.slice(0, 5)}`,
                endTime: `${d.endTime.slice(0, 5)}`,
                resourceId: c.userId,
                groupId: c.userId,
                display: "background",
                backgroundColor: "black",
                eventTextColor: "#FAFAFA",
                textColor: "#FAFAFA",
                title: "Blokada"
              }
            })
            .filter((a) => Boolean(a?.id)) : []

      const appointments = isAvailable ? c.appointments
            .filter((d) =>
              (appointmentsDataConfig.locationId ? appointmentsDataConfig.locationId === d.locationId : true)
            )
            .map((d) => {
              const event = {
                ...d,
                end: moment(d.start.toDate())
                  .add(Number(d.duration), "minutes")
                  .toDate(),
                resourceId: c.userId,
                eventTextColor: "#FAFAFA",
                textColor: "#FAFAFA",
                locationId: d.locationId,
                theraphistId: c.userId,
                paymentStatus: d.paymentStatus
              }

              if (d.appointmentStatus === "COMPLETED") {
                event.backgroundColor = "#28c76f"
                event.borderColor = "#28c76f"
              }
              if (d.appointmentStatus === "CANCELED-PAID") {
                event.backgroundColor = "#ea5455"
                event.borderColor = "#ea5455"
              }
              if (d.appointmentStatus === "CANCELED") {
                event.backgroundColor = "#ff9f43"
                event.borderColor = "#ff9f43"
              }
              if (c.isReservation) {
                event.backgroundColor = "#455d7a"
                event.borderColor = "#455d7a"
              }

              return event
            })
            .filter((a) => Boolean(a)) : []
      p.push(...availability)
      p.push(...timeOff)
      p.push(...blocks)
      p.push(...appointments)
      return p
    }, [])

  const filteredResources = uniqBy(resources, "userId").map((r) => ({
    id: r.id,
    title: r.name
  }))

  filteredResources.forEach((r) => {
    if (r && !filledResources[r.id]) {
      const event = {
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
        resourceId: r.id,
        display: "inverse-background",
        groupId: r.id,
        theraphistId: r.id,
        startTime: "00:00",
        endTime: "00:00",
        backgroundColor: "#B8B8B8"
      }
      filteredData.push(event)
    }
  })

  return {
    events: filteredData,
    resources: filteredResources
  }
}

export const globalCalendarSlice = createSlice({
  name: "globalCalendarNew",
  initialState: {
    userServiceVariants: [],
    userServiceCustomers: [],
    appointmentsData: [],
    availabilityData: [],
    updatedAt: Date.now().toString(),
    notesData: [],
    calendarData: {
      events: [],
      resources: [],
      resourcesToShowByLocation: []
    }
  },
  reducers: {
    regenerateView: (state, action) => {
      state.calendarData = generateCalendarView(
        action.payload.data,
        action.payload.locationId
      )
      state.updatedAt = Date.now().toString()
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTheraphistInfo.fulfilled, (state, action) => {
      state.userServiceVariants = action.payload.variants
      state.userServiceCustomers = action.payload.customers
      state.updatedAt = Date.now().toString()
      state.loading = false
    })
  }
})

export const subscribeToCurrentCalendarView = ({
  dateStart,
  location,
  theraphists,
  theraphyTypes,
  customerCategory,
  appointmentStatus,
  isAvailableStatus
}) => {
  const locationId = location?.id
  const theraphistIds = theraphists.map((t) => t.id)
  const theraphyTypesIds = theraphyTypes.map((t) => t.id)
  const weekNumber = moment(dateStart).isoWeek()
  const getMoreData = appointmentsDataConfig.weekNumber !== weekNumber
  const reinitiate =
    appointmentsDataConfig.locationId !== locationId ||
    JSON.stringify(theraphistIds) !==
      JSON.stringify(appointmentsDataConfig.theraphistIds) ||
    JSON.stringify(theraphyTypesIds) !==
      JSON.stringify(appointmentsDataConfig.theraphyTypesIds)

  appointmentsDataConfig.theraphistIds.length = 0
  theraphistIds.forEach((id) => appointmentsDataConfig.theraphistIds.push(id))
  appointmentsDataConfig.theraphyTypes.length = 0
  theraphyTypes.forEach((id) => appointmentsDataConfig.theraphyTypes.push(id))
  appointmentsDataConfig.theraphyTypesIds.length = 0
  theraphyTypesIds.forEach((id) =>
    appointmentsDataConfig.theraphyTypesIds.push(id)
  )
  appointmentsDataConfig.customerCategory = customerCategory
  appointmentsDataConfig.appointmentStatus = appointmentStatus
  appointmentsDataConfig.isAvailableStatus = isAvailableStatus

  appointmentsDataConfig.usersMap = store
    .getState()
    .users.data.reduce((p, c) => {
      p[c.id] = {
        ...c,
        theraphyTypeIds: c.theraphyTypes ? c.theraphyTypes.map((t) => t.id) : [],
        customerProblemsIds: c.customerProblems ? c.customerProblems.map((t) => t.id) : []
      }

      return p
    }, {})

  appointmentsDataConfig.locationId = locationId
  appointmentsDataConfig.theraphists = theraphists

  if (getMoreData) {
    if (appointmentsDataConfig.appointmentsSub) {
      appointmentsDataConfig.appointmentsSub()
    }
    const constrainsAppointments = []

    constrainsAppointments.push(where("week", "==", weekNumber))

    appointmentsDataConfig.appointmentsSub = onSnapshot(
      query(collection(db, "calendarData"), ...constrainsAppointments),
      (data) => {
        rawData.length = 0
        rawData.concat(...data.docs)
        store.dispatch(
          globalCalendarSlice.actions.regenerateView({
            data: data.docs,
            locationId: appointmentsDataConfig.locationId
          })
        )
      }
    )
  } else if (reinitiate) {
    store.dispatch(
      globalCalendarSlice.actions.regenerateView({
        data: rawData,
        locationId: appointmentsDataConfig.locationId
      })
    )
  }
}

export const unsubscribeFromCurrentCalendarView = () => {
  if (appointmentsDataConfig.appointmentsSub) {
    appointmentsDataConfig.appointmentsSub()
  }
  if (appointmentsDataConfig.availabilitySub) {
    appointmentsDataConfig.availabilitySub()
  }
  if (appointmentsDataConfig.notesSub) {
    appointmentsDataConfig.notesSub()
  }
}

export default globalCalendarSlice.reducer
